import React from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import "./Exchange.css";

const BuyWallet = (props) => {
  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-back-btn-sec" onClick={() => props.setStep(1)}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="buy-title-sec">
            <span>BUY</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
        </div>
        <div className="buy-body-sec">
          <h3>Enter Wallet Address To Recieve The Tokens</h3>
          <Form className="buy-form wallet-form">
            <InputGroup className="mb-3">
              <Form.Control placeholder="65541584981651465gfdfg65Re5" />
              <InputGroup.Text id="basic-addon2">
                <Button>
                  <Image src={window.location.origin + "/img/copy.svg"} />
                </Button>
              </InputGroup.Text>
            </InputGroup>
            <div className="transaction-or-btn text-center">
              <span>Or</span>
            </div>
            <p className="form-content">
              Connect Metamask To Recieve The Tokens
            </p>
            <div className="meta-btn">
              <Button>
                <span>
                  <Image src={window.location.origin + "/img/metamask.png"} />
                </span>
                Connect Metamask
              </Button>
            </div>
            <div className="next-btn">
              <Button className="default-btn" onClick={() => props.setStep(3)}>
                Next
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default BuyWallet;
