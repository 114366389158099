import React, { useEffect, useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Dropdown } from "react-bootstrap";
import configuration from "react-global-configuration";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";

const HeaderIndex = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken"))
      props.dispatch(fetchUserDetailsStart({ navigate }));
  }, []);

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Link className="navbar-brand" to="/">
              <Image className="logo" src="../img/logo-exchange.png" />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              {/* <Image
                className="lazyload hamburger-menu"
                src={window.location.origin + "/img/menu.svg"}
              /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#ef6838"
                viewBox="0 0 24 24"
              >
                <path d="M7 0H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM20 0h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM7 13H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2zM20 13h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2z"></path>
              </svg>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Link to="/how-it-works" className="nav-link">
                  How it works?
                </Link>
                <Link to="/faq" className="nav-link smooth">
                  FAQ
                </Link>
                <div className="nav-item">
                  {!localStorage.getItem("userId") ||
                  !localStorage.getItem("accessToken") ? (
                    <Link to="/login" className="nav-link">
                      Login
                    </Link>
                  ) : !props.profile.loading &&
                    Object.keys(props.profile.data).length > 0 ? (
                    // <Link to="/profile" className="nav-link smooth">
                    //   {`Welcome ${localStorage.getItem("name")}`}
                    // </Link>
                    <div className="user-dropdown-sec">
                    <Dropdown onToggle={handleDropdownToggle} show={dropdownOpen}>
                      <Dropdown.Toggle id="dropdown-basic">
                        {/* <Image
                          className="user-dropdown-img"
                          src={window.location.origin + "/img/banner-img.png"}
                          type="image/png"
                        /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="#fff"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zM8 21.164V19c0-.552.449-1 1-1h6c.551 0 1 .448 1 1v2.164c-1.226.537-2.578.836-4 .836s-2.774-.299-4-.836zm10-1.169V19c0-1.654-1.346-3-3-3H9c-1.654 0-3 1.346-3 3v.995A9.991 9.991 0 012 12C2 6.486 6.486 2 12 2s10 4.486 10 10a9.992 9.992 0 01-4 7.995zM12 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                        </svg>
                        <span>John</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link to="/profile" onClick={() => handleDropdownClose()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                          </svg>
                          <span>Profile</span>
                        </Link>
                        <Link to="/logout">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M11.476 15a1 1 0 00-1 1v3a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2.476a3 3 0 013 3v3a1 1 0 002 0V5a5.006 5.006 0 00-5-5H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h2.476a5.006 5.006 0 005-5v-3a1 1 0 00-1-1z"></path>
                            <path d="M22.867 9.879l-4.586-4.586a1 1 0 10-1.414 1.414l4.263 4.263L6 11a1 1 0 000 2l15.188-.03-4.323 4.323a1 1 0 101.414 1.414l4.586-4.586a3 3 0 00.002-4.242z"></path>
                          </svg>
                          <span>Logout</span>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  ) : null}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(HeaderIndex);
