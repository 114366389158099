import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { parseEther } from "ethers/lib/utils";
import {
  usePrepareSendTransaction,
  useSendTransaction,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
  useBalance,
} from "wagmi";
import configuration from "react-global-configuration";
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Web3 from "web3";
import { BigNumber, ethers } from 'ethers';
import { buyTokensStart } from '../../store/actions/TransactionAction';
import { getErrorNotificationMessage } from '../../helper/ToastNotification';


const PaymentBtn = ({ isLoading, setIsLoading, ...props }) => {
  const constants = configuration.get("configData");

  const balance = useBalance({
    addressOrName: props.address,
  });
  const network = useNetwork();
  const networkSwitcher = useSwitchNetwork();



  //Metamast Payment
  const [debouncedTo] = useDebounce(constants.admin_wallet_address, 500);
  const [allowedTokens, setAllowedTokens] = useState(0);
  // const [debouncedValue] = useDebounce(props.buyData.exchangeValue, 500);
  const web3 = new Web3(window.ethereum);
  const decimals = web3.utils.toBN(18);
  let tokens = props.buyData.exchangeValue;

  useEffect(() => {
    try {
      setAllowedTokens(web3.utils.toWei(tokens.toString(), "Ether"));
    } catch (error) {
      getErrorNotificationMessage(error.message);
    }
  }, []);

  const { config } = usePrepareSendTransaction({
    request: {
      to: debouncedTo,
      value: BigNumber.from(allowedTokens),
    },
  })
  const { data, sendTransaction } = useSendTransaction({
    ...config,
    onError(error) {
      console.log('Error', error)
      setIsLoading(false);
    },
  });

  const transactionWait = useWaitForTransaction({
    hash: data?.hash,
    onSuccess(data) {
      console.log(data);
      paymentFinished(data.transactionHash);
    },
    onError(error) {
      setIsLoading(false);
    }
  })

  const paymentViaMetaMask = () => {
    sendTransaction?.();
    setIsLoading(true);
  }

  const paymentFinished = (transactionHash) => {
    console.log("Buy Data", props.buyData);

    setTimeout(() => {
      props.setBuyData({
        ...props.buyData,
        user_transaction_hash: transactionHash,
      })
      props.dispatch(buyTokensStart({
        net_id: network.chain.id,
        tokens: props.buyData.buyTokens,
        amount: props.buyData.exchangeValue,
        amount_type: props.buyData.paymentMethod.value,
        wallet_address: props.buyData.toWalletAddress,
        to_wallet_address: props.buyData.toWalletAddress,
        user_payment_type: "CRYPTO",
        user_transaction_hash: transactionHash,
      }));
      setIsLoading(false);
      props.setStep(4);
    }, 20000);
  }

  return (
    <>
      <div className="pay-via-wallet-btn-sec">
        {/* {network.chain.id == props.buyData.paymentMethod.id ? */}
        {Number(balance.data?.formatted) > props.buyData.exchangeValue ?
          <Button
            className="default-btn"
            disabled={isLoading || props.buyTokens.buttonDisable || allowedTokens == 0}
            onClick={() => paymentViaMetaMask()}>
            {isLoading || props.buyTokens.loadingButtonContent ?
              "Loading..."
              : "Pay via Metamask"
            }
          </Button>
          : <Button
            className="default-btn"
            onClick={() => getErrorNotificationMessage("Insufficent Balance")}>
            Pay via Metamask
          </Button>
        }
        {/* :
          <Button
            className="default-btn"
            disabled={props.buyTokens.buttonDisable}
            onClick={() => networkSwitcher.switchNetwork(props.buyData.paymentMethod.id)}>
            Switch to {props.buyData.paymentMethod.label}
          </Button>
        } */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  buyTokens: state.transaction.buyTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(PaymentBtn);