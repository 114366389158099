import {
    FETCH_FAQS_LIST_START,
    FETCH_FAQS_LIST_SUCCESS,
    FETCH_FAQS_LIST_FAILURE,
   
}
    from "./ActionConstant";

export function fetchFaqsListStart(data) {
    return {
        type: FETCH_FAQS_LIST_START,
        data,
    };
}


export function fetchFaqsListSuccess(data){
    return {
        type:FETCH_FAQS_LIST_SUCCESS,
        data,
    };
}
export function fetchFaqsListFailure(error){
    return{
        type:FETCH_FAQS_LIST_FAILURE,
        error,
    }
}