import React, { useEffect, useState } from "react";
import {
  Image,
  Tab,
  Nav,
  Row,
  Container,
  Col,
  Accordion,
} from "react-bootstrap";
import { fetchFaqsListStart } from "../store/actions/HomePageAction";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const FAQIndex = (props) => {
  const [faqType, setFaqType] = useState("buy")
  const t = useTranslation();
  useEffect(() => {
    props.dispatch(fetchFaqsListStart({
      type: faqType,
    }))
  }, [faqType])
  return (
    <>
      <div className="faq-sec sm-padding" id="faq" ref={props.forwardRef}>
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} lg={9} className="text-center">
              <div className="section-title">
                <h2>
                  <Image
                    src={window.location.origin + "/img/coin-img.png"}
                    className="section-coin-icon"
                  />
                  <div className="section-title-info">
                    {t("frequently")}
                    <span>{t("asked_questions")}</span>
                  </div>
                  <Image
                    src={window.location.origin + "/img/coin-img.png"}
                    className="section-coin-icon"
                  />
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="first" onClick={() => setFaqType("buy")}>{t("buy")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second" onClick={() => setFaqType("sell")}>{t("sell")}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        {props.faqsList.loading ?
                          [...Array(1)].map((i) => (
                            <Skeleton count={4} height={50} />
                          ))
                          :
                          props.faqsList.data.faqs.map(
                            (list, i) =>
                              <Accordion defaultActiveKey="0">
                                < Accordion.Item eventKey={i}>
                                  <Accordion.Header>
                                    {list.title}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {list.description}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                          )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        {props.faqsList.loading ?
                          [...Array(1)].map((i) => (
                            <Skeleton count={4} height={50} />
                          ))
                          :
                          props.faqsList.data.faqs.map(
                            (list, i) =>

                              <Accordion defaultActiveKey="5">
                                <Accordion.Item eventKey={i}>
                                  <Accordion.Header>
                                    {list.title}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {list.description}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                          )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
            <Col md={6}>
              <div className="faq-img-sec">
                <Image
                  src={window.location.origin + "/img/3D-ILLUSTRATION-3.png"}
                  className="faq-img m-auto"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  faqsList: state.homePage.faqsList,
});
function mapDispatchToProps(dispatch) {
  return { dispatch };

}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(FAQIndex));
