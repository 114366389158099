import React from "react";
import {
  Image,
  Form,
  InputGroup,
  Button,
  Row,
  Container,
  Col,
} from "react-bootstrap";

const HowItsWorkIndex = (props) => {
  return (
    <>
      <div className="about-us-sec sm-padding">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} lg={9} className="text-center">
              <div className="section-title">
                <h2>
                  <Image
                    src={window.location.origin + "/img/coin-img.png"}
                    className="section-coin-icon"
                  />
                  <div className="section-title-info">
                    How It
                    <span>Works ?</span>
                  </div>
                  <Image
                    src={window.location.origin + "/img/coin-img.png"}
                    className="section-coin-icon"
                  />
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="how-its-works-img-sec">
                <Image
                  src={window.location.origin + "/img/how-it-works.svg"}
                  className="how-its-works-img m-auto"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HowItsWorkIndex;
