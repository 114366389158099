import React, { useEffect, useState } from 'react';
import configuration from "react-global-configuration";
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Web3 from "web3";
import { useDebounce } from 'use-debounce';
import {
  useAccount,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';
import Token from '../../../abis/Token.json';
import { BigNumber, ethers } from 'ethers';
import { sellTokensStart } from '../../store/actions/TransactionAction';

const SellPaymentBtn = ({ isLoading, setIsLoading, ...props }) => {
  const constants = configuration.get("configData");
  const network = useNetwork();
  const networkSwitcher = useSwitchNetwork();

  const [debouncedTo] = useDebounce(constants.admin_wallet_address, 500);
  const { address, connector, isConnected, isConnecting } = useAccount();

  const [tokenAmountHex, setTokenAmountHex] = useState(0);


  const web3 = new Web3(window.ethereum);
  const decimals = web3.utils.toBN(18);
  let allowed_tokens = props.sellData.sellTokens;


  useEffect(() => {
    // const tokenAmount = web3.utils.toBN(allowed_tokens);
    // setTokenAmountHex("0x" + tokenAmount.mul(web3.utils.toBN(10).pow(decimals)).toString("hex"));
    const tokenAmount = web3.utils.toWei(allowed_tokens.toString(), "Ether");
    setTokenAmountHex(tokenAmount);
  }, []);

  //Approve
  const approveConfig = usePrepareContractWrite({
    address: constants.pscn_contract_address,
    abi: Token.abi,
    functionName: 'approve',
    args: [address, tokenAmountHex],
    onError(error) {
      console.log('Approve Config Error', error);
      setIsLoading(false);
    },
    onSettled(data, error) {
      console.log('Approve Config Settled', { data, error });
      // setIsLoading(false);
    },
  });
  const approveData = useContractWrite({
    ...approveConfig.config,
    onError(error) {
      console.log('Approve Error', error);
      setIsLoading(false);
    },
    onSettled(data, error) {
      console.log('Approve Settled', { data, error });
    },
  });
  const approveWait = useWaitForTransaction({
    hash: approveData.data?.hash,
    onSuccess(data) {
      console.log('Approve Waiting Success', data);
      paymentData.write();
    },
    onError(error) {
      console.log('Approve Waiting Error', error);
      setIsLoading(false);
    },
    onSettled(data, error) {
      console.log('Approve waiting Settled', { data, error });
    },
  });

  //Payment
  const paymentConfig = usePrepareContractWrite({
    address: constants.pscn_contract_address,
    abi: Token.abi,
    functionName: 'transfer',
    args: [constants.admin_wallet_address, tokenAmountHex],
    overrides: {
      gasPrice: ethers.utils.parseUnits("20", "gwei"),
      gasLimit: 200000,
    },
  });
  const paymentData = useContractWrite({
    ...paymentConfig.config,
    onError(error) {
      console.log('Payment Error', error);
      setIsLoading(false);
    },
    onSettled(data, error) {
      console.log('Payment Settled', { data, error });
      // setIsLoading(false);
    },
    onSuccess(data) {
      console.log('Payment Success', data);
      setTimeout(() => {
        if (props.sellData.paymentMethod.symbol === "USD") {
          props.dispatch(sellTokensStart({
            net_id: network.chain.id,
            tokens: props.sellData.sellTokens,
            amount: props.sellData.exchangeValue,
            amount_type: props.sellData.paymentMethod.symbol,
            admin_payment_type: "BANK_TRANSFER",
            user_transaction_hash: data.hash,
            ...props.sellData.bankDetails,
          }));
        } else {
          props.dispatch(sellTokensStart({
            net_id: network.chain.id,
            tokens: props.sellData.sellTokens,
            amount: props.sellData.exchangeValue,
            amount_type: props.sellData.paymentMethod.symbol,
            to_wallet_address: props.sellData.toWalletAddress,
            wallet_address: props.sellData.toWalletAddress,
            admin_payment_type: "CRYPTO",
            user_transaction_hash: data.hash,
          }));
        }
        setIsLoading(false);
        props.setStep(4);
      }, 20000);
    },
  });

  const paymentViaMetaMask = () => {
    // approveData.write();
    paymentData.write();
    setIsLoading(true);
  }

  return (
    <>
      <div className="pay-via-wallet-btn-sec">
        {/* {network.chain.id == Number(constants.binance_network_id) ? */}
        {address ?
          <Button
            className="default-btn"
            disabled={isLoading || props.sellTokens.buttonDisable || tokenAmountHex == 0}
            onClick={() => paymentViaMetaMask()}>
            {isLoading || props.sellTokens.loadingButtonContent ?
              "Loading"
              : "Pay via Metamask"
            }
          </Button>
          : null
        }
        {/* :
          <Button
            className="default-btn"
            disabled={isLoading}
            onClick={() => networkSwitcher.switchNetwork(Number(constants.binance_network_id))}>
            Switch to {constants.binance_native_currency_symbol}
          </Button>
        } */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  sellTokens: state.transaction.sellTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(SellPaymentBtn);