import React, { useEffect, useState } from 'react';
import { parseEther } from "ethers/lib/utils";
import configuration from "react-global-configuration";
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { buyTokensStart } from '../../store/actions/TransactionAction';
import { getErrorNotificationMessage } from '../../helper/ToastNotification';
import Web3 from "web3";
import {
  useAccount,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';
import Token from '../../../abis/Token.json';
import { BigNumber, ethers } from 'ethers';


const TokenPaymentBtn = ({ isLoading, setIsLoading, ...props }) => {
  const constants = configuration.get("configData");
  const network = useNetwork();

  const { address, connector, isConnected, isConnecting } = useAccount();

  const [tokenAmountHex, setTokenAmountHex] = useState(0);

  const web3 = new Web3(window.ethereum);
  const decimals = web3.utils.toBN(18);
  let allowed_tokens = props.buyData.exchangeValue;

  useEffect(() => {
    // const tokenAmount = web3.utils.toBN(allowed_tokens);
    // setTokenAmountHex("0x" + tokenAmount.mul(web3.utils.toBN(10).pow(decimals)).toString("hex"));
    const tokenAmount = web3.utils.toWei(allowed_tokens.toString(), "Ether");
    setTokenAmountHex(tokenAmount);
    console.log("Token Amount", tokenAmount);
  }, []);

  //Approve
  const approveConfig = usePrepareContractWrite({
    address: props.buyData.paymentMethod.contractAddress,
    abi: Token.abi,
    functionName: 'approve',
    args: [address, tokenAmountHex],
    onError(error) {
      console.log('Approve Config Error', error);
      setIsLoading(false);
    },
    onSettled(data, error) {
      console.log('Approve Config Settled', { data, error });
      // setIsLoading(false);
    },
  });
  const approveData = useContractWrite({
    ...approveConfig.config,
    onError(error) {
      console.log('Approve Error', error);
      setIsLoading(false);
    },
    onSettled(data, error) {
      console.log('Approve Settled', { data, error });
    },
  });
  const approveWait = useWaitForTransaction({
    hash: approveData.data?.hash,
    onSuccess(data) {
      console.log('Approve Waiting Success', data);
      paymentData.write();
    },
    onError(error) {
      console.log('Approve Waiting Error', error);
      setIsLoading(false);
    },
    onSettled(data, error) {
      console.log('Approve waiting Settled', { data, error });
    },
  });

  //Payment
  const paymentConfig = usePrepareContractWrite({
    address: props.buyData.paymentMethod.contractAddress,
    abi: Token.abi,
    functionName: 'transfer',
    args: [constants.admin_wallet_address, tokenAmountHex],
    overrides: {
      gasPrice: ethers.utils.parseUnits("20", "gwei"),
      gasLimit: 200000,
    },
  });
  const paymentData = useContractWrite({
    ...paymentConfig.config,
    onError(error) {
      console.log('Payment Error', error);
      setIsLoading(false);
    },
    onSettled(data, error) {
      console.log('Payment Settled', { data, error });
      // setIsLoading(false);
    },
    onSuccess(data) {
      console.log('Payment Success', data);
      setTimeout(() => {
        props.setBuyData({
          ...props.buyData,
          user_transaction_hash: data.hash,
        })
        props.dispatch(buyTokensStart({
          net_id: network.chain.id,
          tokens: props.buyData.buyTokens,
          amount: props.buyData.exchangeValue,
          amount_type: props.buyData.paymentMethod.value,
          wallet_address: props.buyData.toWalletAddress,
          to_wallet_address: props.buyData.toWalletAddress,
          user_payment_type: "CRYPTO",
          user_transaction_hash: data.hash,
        }));
        setIsLoading(false);
        props.setStep(4);
      }, 20000);
    },
  });

  const paymentViaMetaMask = () => {
    paymentData.write();
    // approveData.write();
    setIsLoading(true);
  }

  return (
    <>
      <div className="pay-via-wallet-btn-sec">
        <Button
          className="default-btn"
          disabled={isLoading || props.buyTokens.buttonDisable || allowed_tokens == 0}
          onClick={() => paymentViaMetaMask()}>
          {isLoading || props.buyTokens.loadingButtonContent ?
            "Loading..."
            : "Pay via Metamask"
          }
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  buyTokens: state.transaction.buyTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(TokenPaymentBtn);