import React, { useState, useEffect } from 'react';
import './App.css';
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./components/store";
import { Provider } from "react-redux";
import { apiConstants } from "./components/Constant/constants";
import configuration from "react-global-configuration";
import { WagmiConfig, createClient, chain } from 'wagmi';
import { Buffer } from "buffer";
import { ConnectKitProvider, getDefaultClient } from "connectkit";

const alchemyId = process.env.ALCHEMY_ID;

const createWalletClient = () => {
  const binanceChain = {
    id: 97,
    name: "Binance",
    nativeCurrency: {
      decimals: 18,
      name: "Binance",
      symbol: "BNB",
    },
    rpcUrls: {
      default: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    },
    blockExplorers: {
      default: { name: "Binance Test", url: "https://testnet.bscscan.com/" },
      // snowtrace: { name: "SnowTrace", url: "https://testnet.bscscan.com" },
    },
    testnet: true,
  };

  // const avalancheChain = {
  //   id: Number(configuration.get("configData.chain_id")),
  //   name: configuration.get("configData.chain_name"),
  //   nativeCurrency: {
  //     decimals: configuration.get("configData.native_currency_decimals"),
  //     name: configuration.get("configData.native_currency_name"),
  //     symbol: configuration.get("configData.native_currency_symbol"),
  //   },
  //   rpcUrls: {
  //     default: configuration.get("configData.rpc_url"),
  //   },
  //   blockExplorers: {
  //     default: { name: "IDSOL Test", url: configuration.get("configData.block_explorer_url") },
  //     // snowtrace: { name: "SnowTrace", url: "https://testnet.bscscan.com" },
  //   },
  //   testnet: true,
  // };

  // const etheriumChain = {
  //   id: 4,
  //   name: "Ethereum",
  //   nativeCurrency: {
  //     decimals: 18,
  //     name: "Rinkeby",
  //     symbol: "RinkebyETH",
  //   },
  //   rpcUrls: {
  //     default: "https://rinkeby.infura.io/v3/",
  //   },
  //   blockExplorers: {
  //     default: { name: "Ethereum Test", url: "https://rinkeby.etherscan.io/" },
  //     // snowtrace: { name: "SnowTrace", url: "https://testnet.bscscan.com" },
  //   },
  //   testnet: true,
  // }

  const poshcoinChain = {

  }

  const chains = [binanceChain];
  if (!window.Buffer) window.Buffer = Buffer;
  const client = createClient({
    ...getDefaultClient({
      appName: "Your App Name",
      alchemyId,
      chains,
    }),
  });
  return client;
}

function App() {
  // const [client, setClient] = useState();
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      // setClient(createWalletClient());
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  return (
    <Provider store={store}>
      {!configLoading && (
            <Base />
      )}
    </Provider>
  );
}

export default App;
