import React, { useState, useEffect } from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { getSuccessNotificationMessage } from '../../helper/ToastNotification';
import configuration from "react-global-configuration";

const BuySuccess = (props) => {
  const constants = configuration.get("configData");
  const [show, setShow] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (Object.keys(props.buyTokens.data).length > 0) {
      if (props.buyTokens.data.admin_payment_status == 1) {
        setIsCompleted(true);
      }
      setShow(true);
    } else {
      props.reset();
    }
  }, []);

  const copy = (message) => {
    props.dispatch(getSuccessNotificationMessage(message))
  }

  return (
    <>
      {show ?
        <div className="buy-sec">
          <div className="buy-header-sec">
            <div className="buy-back-btn-sec" onClick={() => props.reset()}>
              <Image
                src={window.location.origin + "/img/buy/back-arrow.svg"}
                className="back-arrow-icon"
              />
              <span>Back</span>
            </div>
            <div className="buy-title-sec">
              <span>BUY</span>
              <Image
                src={window.location.origin + "/img/buy/buy-icon.svg"}
                className="buy-icon"
              />
            </div>
          </div>
          <div className="buy-body-sec">
            <div className="buy-success-img-sec">
              <Image
                src={isCompleted ?
                  window.location.origin + "/img/success.gif"
                  : window.location.origin + "/img/pending.gif"
                }
                className="buy-success-img"
              />
              {!isCompleted ?
                <h5>
                  Payment Recieved Tokens will be added to your account in 2 Hours
                </h5>
                : null
              }
              <div className="you-will-be-getting-sec">
                <p>Reference ID -</p>
                <div className="you-will-be-getting-card">
                  <span>{props.buyTokens.data.payment_id}</span>
                  <CopyToClipboard text={props.buyTokens.data.payment_id}
                    onCopy={() => copy("Transacton id copied")}
                  >
                    <Button>
                      <Image src={window.location.origin + "/img/copy.svg"} />
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
              {props.buyTokens.data.user_transaction_hash ?
                <div className="you-will-be-getting-sec">
                  <p>{props.buyData.paymentMethod.label} Hash -</p>
                  <div>
                    <div className="you-will-be-getting-card">
                      <span>{
                        `${props.buyTokens.data.user_transaction_hash.substring(0, 4)}
                  ...
                  ${props.buyTokens.data.user_transaction_hash.substring(
                          props.buyTokens.data.user_transaction_hash.length - 4,
                          props.buyTokens.data.user_transaction_hash.length
                        )}
                  `}</span>
                      <CopyToClipboard text={props.buyTokens.data.user_transaction_hash}
                        onCopy={() => copy(`${props.buyData.paymentMethod.label} hash copied`)}
                      >
                        <Button>
                          <Image src={window.location.origin + "/img/copy.svg"} />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    <a
                      href={`${props.buyData.paymentMethod.blockExplorerUrl}tx/${props.buyTokens.data.user_transaction_hash}`}
                      target="_blank"
                    >View on explorer</a>
                  </div>
                </div>
                : null
              }
              {props.buyTokens.data.admin_transaction_hash ?
                <div className="you-will-be-getting-sec">
                  <p>PSCN Hash -</p>
                  <div>
                    <div className="you-will-be-getting-card">
                      <span>{
                        `${props.buyTokens.data.admin_transaction_hash.substring(0, 4)}
                  ...
                  ${props.buyTokens.data.admin_transaction_hash.substring(
                          props.buyTokens.data.admin_transaction_hash.length - 4,
                          props.buyTokens.data.admin_transaction_hash.length
                        )}
                  `}</span>
                      <CopyToClipboard text={props.buyTokens.data.admin_transaction_hash}
                        onCopy={() => copy(`PSCN hash copied`)}
                      >
                        <Button>
                          <Image src={window.location.origin + "/img/copy.svg"} />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    <a href={`${constants.binance_block_explorer_urls}tx/${props.buyTokens.data.admin_transaction_hash}`}
                      target="_blank"
                    >View on explorer</a>
                  </div>
                </div>
                : null
              }
            </div>
            <div className="next-btn">
              <Button className="default-btn" onClick={() => props.reset()}>
                Home
              </Button>
            </div>
          </div>
        </div>
        : null
      }
    </>
  );
};


const mapStateToProps = (state) => ({
  buyTokens: state.transaction.buyTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(BuySuccess);
