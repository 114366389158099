import {
  BUY_TOKENS_FAILURE,
  BUY_TOKENS_START,
  BUY_TOKENS_SUCCESS,
  FETCH_MORE_WALLET_TRANSACTION_LIST_START,
  FETCH_WALLET_TRANSACTION_DETAILS_FAILURE,
  FETCH_WALLET_TRANSACTION_DETAILS_START,
  FETCH_WALLET_TRANSACTION_DETAILS_SUCCESS,
  FETCH_WALLET_TRANSACTION_LIST_FAILURE,
  FETCH_WALLET_TRANSACTION_LIST_START,
  FETCH_WALLET_TRANSACTION_LIST_SUCCESS,
  SELL_TOKENS_FAILURE,
  SELL_TOKENS_START,
  SELL_TOKENS_SUCCESS,
  STRIPE_PAYMENT_FAILURE,
  STRIPE_PAYMENT_START,
  STRIPE_PAYMENT_SUCCESS
} from "../actions/ActionConstant";

const initialState = {
  buyTokens: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  sellTokens: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  walletTransactions: {
    data: {
      wallet_transactions:[],
      total_wallet_transactions:0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleTransaction: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  stripePayment: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
}

const TransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUY_TOKENS_START:
      return {
        ...state,
        buyTokens: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case BUY_TOKENS_SUCCESS:
      return {
        ...state,
        buyTokens: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case BUY_TOKENS_FAILURE:
      return {
        ...state,
        buyTokens: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SELL_TOKENS_START:
      return {
        ...state,
        sellTokens: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case SELL_TOKENS_SUCCESS:
      return {
        ...state,
        sellTokens: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SELL_TOKENS_FAILURE:
      return {
        ...state,
        sellTokens: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_WALLET_TRANSACTION_LIST_START:
      return {
        ...state,
        walletTransactions: {
          data: {
            ...state.walletTransactions.data,
            wallet_transactions:[],
            total_wallet_transactions:0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
     
        
    case FETCH_WALLET_TRANSACTION_LIST_SUCCESS:
      return { 
        ...state,
        walletTransactions: {
          data: {
            wallet_transactions:[...state.walletTransactions.data.wallet_transactions, ...action.data.wallet_transactions],
            total_wallet_transactions:action.data.total_wallet_transactions,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
     
        
    case FETCH_WALLET_TRANSACTION_LIST_FAILURE:
      return {
        ...state,
        walletTransactions: {
          data: state.walletTransactions.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    
    case FETCH_WALLET_TRANSACTION_DETAILS_START:
      return {
        ...state,
        singleTransaction: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_WALLET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        singleTransaction: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
      case FETCH_WALLET_TRANSACTION_DETAILS_FAILURE:
        return {
          ...state,
          singleTransaction:{
            data:{},
            loading:false,
            error:action.error,
          }
        };
    case FETCH_WALLET_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        singleTransaction: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case FETCH_MORE_WALLET_TRANSACTION_LIST_START:
      return state;
      
    case STRIPE_PAYMENT_START:
      return {
        ...state,
        stripePayment: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      }
    case STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        stripePayment: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null
        }
      }
    case STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        stripePayment: {
          data: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
      
        
    default: return state;
  }
}

export default TransactionReducer;