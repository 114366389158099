import React, { useEffect, useState } from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import {
  useAccount
} from "wagmi";
import { ConnectKitButton } from "connectkit";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import { connect } from "react-redux";
import { buyTokensStart } from "../../store/actions/TransactionAction";
import PaymentBtn from "./PaymentBtn";
import configuration from "react-global-configuration";
import WalletConnectorIndex from "../../WalletConnector/WalletConnectorIndex";
import Web3 from "web3";
import TokenPaymentBtn from './TokenPaymentBtn';

const ScanPayment = (props) => {
  const constants = configuration.get("configData");
  const web3 = new Web3(window.ethereum);
  const [skipRender, setSkipRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const account = useAccount();

  const copy = () => {
    props.dispatch(getSuccessNotificationMessage("Wallet address copied"))
  }

  const onValidateTransactionId = async () => {
    props.dispatch(buyTokensStart({
      net_id: props.buyData.paymentMethod.id,
      tokens: props.buyData.buyTokens,
      amount: props.buyData.exchangeValue,
      amount_type: props.buyData.paymentMethod.value,
      wallet_address: props.buyData.toWalletAddress,
      to_wallet_address: props.buyData.toWalletAddress,
      user_payment_type: "CRYPTO",
      user_transaction_hash: props.buyData.transactionId,
    }));
    props.setStep(4);
  }


  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-back-btn-sec" onClick={() => props.setStep(2)}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="buy-title-sec">
            <span>BUY</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
        </div>
        <div className="buy-body-sec buy-body-sec-new">
          {/* <h3>
            Publishing and graphic design, Lorem ipsum is a placeholder text{" "}
          </h3> */}
          {/* <InputGroup className="mb-3">
              <Form.Control
                placeholder="65541584981651465gfdfg65Re5"
                value={admin_wallet_address}
                disabled={true}
              />
              <InputGroup.Text id="basic-addon2">
                <CopyToClipboard text={admin_wallet_address}
                  onCopy={() => copy()}
                >
                  <Button>
                    <Image src={window.location.origin + "/img/copy.svg"} />
                  </Button>
                </CopyToClipboard>
              </InputGroup.Text>
            </InputGroup> */}
          <div className="wallet-scan mb-3">
            <Image src={window.location.origin + "/img/scan.png"} className="qr-scan-img" />
          </div>
          <div className="you-will-be-getting-card mb-3">
            <span>
              {props.buyData.exchangeValue} {props.buyData.paymentMethod.label}
            </span>
          </div>
          {/* <InputGroup className="mb-3">
              <Form.Control
                placeholder="65541584981651465gfdfg65Re5"
                value={admin_wallet_address}
                disabled={true}
              />
              <InputGroup.Text id="basic-addon2">
                <CopyToClipboard text={admin_wallet_address}
                  onCopy={() => copy()}
                >
                  <Button>
                    <Image src={window.location.origin + "/img/copy.svg"} />
                  </Button>
                </CopyToClipboard>
              </InputGroup.Text>
            </InputGroup> */}
          <div className="buy-scan-copy-sec">
            <div className="buy-scan-hash-id">
              <span value={constants.admin_wallet_address}>
                {`
                  ${constants.admin_wallet_address.substring(0, 12)}
                  ...
                  ${constants.admin_wallet_address.substring(
                  constants.admin_wallet_address.length - 7,
                  constants.admin_wallet_address.length)}
                  `}
              </span>
              <CopyToClipboard text={constants.admin_wallet_address}
                onCopy={() => copy()}
              >
                <Button>
                  <Image src={window.location.origin + "/img/copy.svg"} />
                </Button>
              </CopyToClipboard>
            </div>
          </div>
          {/* <Form.Group className="mb-3 transaction-form-control">
              <Form.Control
                type="text"
                placeholder="Paste Your Transaction ID"
                value={props.transactionId}
                onChange={e => props.setBuyData({
                  ...props.buyData,
                  transactionId: e.target.value
                })}
              />
            </Form.Group> */}
          <Form className="buy-form card-form wallet-form" onSubmit={e => {
            e.preventDefault();
            onValidateTransactionId()
          }}>
            <div className="paste-hash-code-sec">
              <div className="paste-hase-code-input">
                <InputGroup className="mb-0">
                  <Form.Control
                    type="text"
                    placeholder="Paste Your Transaction ID"
                    value={props.buyData.transactionId}
                    onChange={e => props.setBuyData({
                      ...props.buyData,
                      transactionId: e.target.value
                    })}
                    disabled={isLoading}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <Button onClick={() => props.setBuyData({
                      ...props.buyData,
                      transactionId: ""
                    })}>
                      <Image src={window.location.origin + "/img/close-icon.svg"} />
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="paste-hash-code-btn">
                <Button
                  type="submit"
                  className="validate-btn"
                  disabled={!props.buyData.transactionId || props.buyTokens.buttonDisable || isLoading}
                >
                  {
                    props.buyTokens.loadingButtonContent
                      ? props.buyTokens.loadingButtonContent
                      : "Validate"
                  }
                </Button>
              </div>
            </div>
            {/* <div className="next-btn">
              <Button
                className="default-btn"
                disabled={!props.buyData.transactionId}
                onClick={() => props.setStep(4)}>
                Buy Tokens
              </Button>
            </div> */}
          </Form>
        </div>

        <div className="buy-footer-sec">
          <div className="or-divider">Or</div>
          {account.address ?
            props.buyData.paymentMethod.isNetwork ?
              <PaymentBtn
                buyData={props.buyData}
                setBuyData={props.setBuyData}
                address={account.address}
                setStep={props.setStep}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
              : <TokenPaymentBtn
                buyData={props.buyData}
                setBuyData={props.setBuyData}
                address={account.address}
                setStep={props.setStep}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            : null
          }
          <div className="scan-btn-sec">
            {account.address ?
              <>
                <div className="scan-details meta-btn">
                  <Button>
                    <span>
                    </span>
                    {`${account.address.substring(0, 5)}....
                    ${account.address.substring(account.address.length - 4, account.address.length)}`}
                  </Button>
                </div>
                <div className="disconnect-btn-sec">
                  <WalletConnectorIndex
                    className="disconnect-btn"
                    chainId={props.buyData.paymentMethod.id}
                    disabled={isLoading} />
                </div>
              </>
              :
              <div className="scan-details meta-btn">
                <WalletConnectorIndex
                  chainId={props.buyData.paymentMethod.id}
                  disabled={isLoading} />
              </div>
            }
          </div>
        </div>
      </div >
    </>
  );
};

const mapStateToProps = (state) => ({
  buyTokens: state.transaction.buyTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ScanPayment);
