import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage
} from "../../helper/ToastNotification";
import {
  AMOUNT_CONVERSION_START,
} from "../actions/ActionConstant";
import {
  amountConversionSuccess,
  amountConversionFailure
} from '../actions/WalletConnectorAction';

function* amountConvertionAPI(action) {
  const { convertFrom, convertTo, isNetwork } = action.data;
  try {
    const response = yield api.getConversion(convertFrom, isNetwork ? convertTo : "usd");
    if (response.status === 200) {
      if (!isNetwork) {
        const secondResponse = yield api.getConversion(convertTo, "usd");
        if (secondResponse.status === 200) {
          const firstConversion = response.data[convertFrom]["usd"];
          const secondConversion = secondResponse.data[convertTo]["usd"];
          yield put(amountConversionSuccess({ amount: firstConversion / secondConversion }));
        } else {
          yield put(amountConversionFailure(response));
          yield call(getErrorNotificationMessage, "Conversion failed please try again later");
        }
      } else {
        yield put(amountConversionSuccess({ amount: response.data[convertFrom][convertTo] }));
      }
    } else {
      yield put(amountConversionFailure(response));
      yield call(getErrorNotificationMessage, "Conversion failed please try again later");
    }
  } catch (error) {
    yield put(amountConversionFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}




export default function* WalletConnectorSaga() {
  yield all([
    yield takeLatest(AMOUNT_CONVERSION_START, amountConvertionAPI),
  ])
}