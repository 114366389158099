import React, { useEffect, useState } from "react";
import {
  Image,
  Form,
  InputGroup,
  Button,
  Row,
  Container,
  FormControl,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";

const ForgotPasswordIndex = (props) => {

    const [otp, setOtp] = useState('');

  return (
    <>
      <div className="login-sec">
        <div className="login-box">
            <Container>
            <Row className="align-items-center">
                <Col md={12} xl={5} lg={5} className="resp-margin-btm-md">
                    <div className="step-1">
                        <Form className="auth-form-sec">
                            <div className="auth-form-box">
                                <div className="auth-logo-sec">
                                    <Link to="/">
                                        <Image
                                            className="logo"
                                            src="../img/logo-exchange.png"
                                        />
                                    </Link>
                                </div>
                                <h2>Forgot Password</h2>
                                <Form.Group controlId="formBasicEmail" className="mb-4">
                                    <Form.Label>Email <span>*</span></Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" />
                                </Form.Group>
                                <div className="auth-btn-sec">
                                    <Button className="default-btn w-100">
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    {/* <div className="step-2">
                        <Form className="auth-form-sec">
                            <div className="auth-form-box">
                                <div className="auth-logo-sec">
                                    <Link to="/">
                                        <Image
                                            className="logo"
                                            src="../img/logo-exchange.png"
                                        />
                                    </Link>
                                </div>
                                <div className="verification-item">
                                    <h4>Enter your code here</h4>
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        // isInputNum={true}
                                        // shouldAutoFocus={true}
                                        separator={<span>-</span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    </div>
                                    <div className="auth-btn-sec">
                                        <Button className="default-btn">
                                            Submit
                                        </Button>
                                    </div>
                                    <div className="verification-btn-sec">
                                    <span>Haven’t received the code?</span>
                                    <Link
                                        to="#"
                                        className="resend-link"
                                    >
                                        Send Again
                                    </Link>
                                </div>
                            </div>
                        </Form>
                    </div> */}
                </Col>
                <Col md={12} xl={7} lg={7}>
                    <div className="auth-right-img-sec">
                        <Image
                            className="auth-right-img"
                            src="../img/auth/exchange-auth-right-img.png"
                        />
                        <div className="auth-right-vertical-center-content">
                            <h3>Become a seller & buyer</h3>
                            <div className="auth-right-info-box">
                                <div className="auth-right-info-card">
                                    <Image
                                        className="auth-right-info-icon"
                                        src="../img/auth/poshcoin.png"
                                    />
                                    <h4>Enter poshCoin</h4>
                                </div>
                                <div className="auth-right-info-card">
                                    <Image
                                        className="auth-right-info-icon"
                                        src="../img/auth/payment.png"
                                    />
                                    <h4>Receive Payment</h4>
                                </div>
                                <div className="auth-right-info-card">
                                    <Image
                                        className="auth-right-info-icon"
                                        src="../img/auth/wallet.png"
                                    />
                                    <h4>Wallet Address</h4>
                                </div>
                                <div className="auth-right-info-card">
                                    <Image
                                        className="auth-right-info-icon"
                                        src="../img/auth/bitcoin.png"
                                    />
                                    <h4>Receive Crypto</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            </Container>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordIndex;
