import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import LandingPageIndexOld from "../LandingPageOld/LandingPageIndexOld";
import en from "../translations/en.json"
import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "../helper/PageNotFound";
import TransactionIndex from "../Transaction/TransactionIndex"
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ProfileIndex from "../Auth/ProfileIndex";
import EditProfileIndex from "../Auth/EditProfileIndex";
import ForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import LogoutIndex from "../Auth/LogoutIndex";

setTranslations({ en });

const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {

  setLanguage("en");

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
        <script src={configuration.get("configData.head_scripts")}></script>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <AppRoute component={LandingPageIndex} layout={MainLayout} />
            }
          />
          <Route
            path="/transaction"
            element={
              <AppRoute component={TransactionIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/old"}
            element={
              <AppRoute component={LandingPageIndexOld} layout={MainLayout} />
            }
          />
          <Route
            path={"/login"}
            element={
              <AppRoute component={LoginIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/register"}
            element={
              <AppRoute component={RegisterIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/forgot-password"}
            element={
              <AppRoute component={ForgotPasswordIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/profile"}
            element={
              <PrivateRoute component={ProfileIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/edit-profile"}
            element={
              <PrivateRoute component={EditProfileIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/logout"}
            element={<PrivateRoute component={LogoutIndex} layout={EmptyLayout} />}
          />
          <Route
            path={"/:section"}
            element={
              <AppRoute component={LandingPageIndex} layout={MainLayout} />
            }
          />
          <Route
            path="/404"
            element={<AppRoute component={PageNotFound} layout={MainLayout} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
