import React, { useState, useEffect, useRef } from "react";
import { Container, Image, Nav, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import AboutUsIndex from "./AboutUsIndex";
import BuyIndex from "./buy/BuyIndex";
import FAQIndex from "./FAQIndex";
import HowItsWorkIndex from "./HowItsWorkIndex";
import SellIndex from "./Sell/SellIndex";
import { useParams, useNavigate } from "react-router-dom";

const LandingPageIndex = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const howItWorks = useRef();
  const faq = useRef();
  const [section, setSection] = useState("buy");

  useEffect(() => {
    if (params.section) {
      if (params.section === "how-it-works") {
        howItWorks.current.scrollIntoView({ behavior: 'smooth', })
      } else if (params.section === "faq") {
        faq.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      } else {
        navigate("/404");
      }
    }
  }, [params]);

  return (
    <>
      <div className="exchange-sec">
        <Container>
          <Row className="align-items-center">
            <Col md={12} xl={6} lg={6}>
              <div className="banner-left-sec">
                <div className="banner-content">
                  <h1>
                    Exchange Made Simple <br></br>
                    To Buy/Sell Your Crypto At True Value{" "}
                  </h1>
                  <p>
                    A simple and safe crypto investment app to build your
                    portfolio using Crypto and Fiat Currency with your
                    debit/credit card.
                  </p>
                  <div className="banner-img-sec">
                    <Image
                      src={window.location.origin + "/img/referfer-1.png"}
                      className="banner-img"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} xl={6} lg={6}>
              {section === "buy" ? (
                <BuyIndex setSection={setSection} />
              ) : (
                <SellIndex setSection={setSection} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <AboutUsIndex />
      <HowItsWorkIndex forwardRef={howItWorks} />
      <FAQIndex forwardRef={faq} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageIndex);
