import React, { useEffect } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Transaction.css";
import { fetchWalletTransactionDetailsStart } from "../store/actions/TransactionAction";
import { connect } from "react-redux";
import { withTranslation, useTranslation, t } from "react-multi-lang";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getSuccessNotificationMessage } from "../helper/ToastNotification";


const TransactionViewModal = (props) => {
    const { transactionView, closeTransactionViewModal, paymentId } = props;

    useEffect(() => {
        if (paymentId !== "") {
            props.dispatch(
                fetchWalletTransactionDetailsStart({
                    search_key: paymentId,
                }
                ))
        }
    }, [paymentId])
    const onCopy = () => {
        props.dispatch(getSuccessNotificationMessage(t("Transaction_id_copied")));
    }
    return (
        <>
            <Modal
                className="modal-dialog-center transaction-view-modal"
                size="lg"
                centered
                show={transactionView}
                onHide={closeTransactionViewModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("transaction_overview_details")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.singleTransaction.loading ?
                        <CommonCenterLoader />
                        :
                        <div className="transaction-view-box">
                            <div className="transaction-view-card" >
                                <h4>
                                    {t("wallet_address")}
                                </h4>
                                <div className="transaction-hash-id-sec">
                                    {props.singleTransaction.data.wallet_transaction.status >= 1 ?
                                        <>
                                            <p>{props.singleTransaction.data.wallet_transaction.wallet_address}</p>
                                            <Button>
                                                <CopyToClipboard
                                                    text={props.singleTransaction.data.wallet_transaction.wallet_address}
                                                    onCopy={() => onCopy()}
                                                >
                                                    <Image src={window.location.origin + "/img/copy.svg"} />
                                                </CopyToClipboard>
                                            </Button>
                                        </>
                                        : <p>{t("n_a")}</p>
                                    }
                                </div>
                            </div>
                            <div className="transaction-view-card">
                                <h4>{t("tokens")}</h4>
                                <p>{props.singleTransaction.data.wallet_transaction.tokens}</p>
                            </div>
                            <div className="transaction-view-card">
                                <h4>{t("amount")}</h4>
                                <p>{props.singleTransaction.data.wallet_transaction.amount}(USD)</p>
                            </div>
                            <div className="transaction-view-card">
                                <h4>{t("user_payment_type")}</h4>
                                <p>{props.singleTransaction.data.wallet_transaction.user_payment_type}</p>
                            </div>
                            <div className="transaction-view-card">
                                <h4>
                                    {t("sent_txt_hash")}
                                </h4>
                                <div className="transaction-hash-id-sec">
                                    {props.singleTransaction.data.wallet_transaction.status >= 1 ?
                                        <>
                                            <p>{props.singleTransaction.data.wallet_transaction.user_transaction_hash}</p>
                                            <Button>
                                                <CopyToClipboard
                                                    text={props.singleTransaction.data.wallet_transaction.user_transaction_hash}
                                                    onCopy={() => onCopy()}
                                                >

                                                    <Image src={window.location.origin + "/img/copy.svg"} />
                                                </CopyToClipboard>
                                            </Button>
                                        </>
                                        : <p>{t("n_a")}</p>
                                    }
                                </div>
                            </div>
                            <div className="transaction-view-card">
                                <h4>
                                    {t("receive_txt_hash")}
                                </h4>
                                <div className="transaction-hash-id-sec">
                                    {props.singleTransaction.data.wallet_transaction.status >= 1 ?
                                        <>
                                            <p>{props.singleTransaction.data.wallet_transaction.admin_transaction_hash}</p>
                                            <Button>
                                                <CopyToClipboard
                                                    text={props.singleTransaction.data.wallet_transaction.admin_transaction_hash}
                                                    onCopy={() => onCopy()}
                                                >
                                                    <Image src={window.location.origin + "/img/copy.svg"} />
                                                </CopyToClipboard>
                                            </Button>
                                        </>
                                        : <p>{t("n_a")}</p>
                                    }
                                </div>
                            </div>
                            <div className="transaction-view-card">
                                <h4>{t("status")}</h4>
                                {props.singleTransaction.data.wallet_transaction.status >= 1 ?
                                    <div className="status-completed">
                                        {t("completed")}
                                        <span>
                                            <Image src={window.location.origin + "/img/status-completed.svg"} />
                                        </span>
                                    </div>
                                    :
                                    <div className="status-pending">
                                        {t("pending")}
                                        <span>
                                            <Image src={window.location.origin + "/img/status-pending.svg"} />
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className="transaction-view-card">
                                <h4>{t("transaction_type")}</h4>
                                <p>{props.singleTransaction.data.wallet_transaction.transaction_type}</p>
                            </div>
                            <div className="transaction-view-card">
                                <h4>{t("BSC Scan Page")}</h4>
                                <Link to="#" className="visit-bsc-btn">
                                    {t("visit_bsc")}
                                </Link>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    singleTransaction: state.transaction.singleTransaction,


});
function mapDispatchToProps(dispatch) {
    return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionViewModal);