import React, { useState, useEffect } from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import {
  useAccount,
} from "wagmi";
import WalletConnectorIndex from "../../WalletConnector/WalletConnectorIndex";
// import { ConnectKitButton } from "connectkit";
import Web3 from "web3";

const WalletConnectSec = (props) => {

  const [isValidAddress, setIsValidAddress] = useState(true);

  const account = useAccount({
    onDisconnect() {
      console.log("Disconnected");
      props.setBuyData({
        ...props.buyData,
        toWalletAddress: ""
      });
    }
  });

  useEffect(() => {
    if (account.address) {
      props.setBuyData({
        ...props.buyData,
        toWalletAddress: account.address
      });
    }
  }, [account.address]);

  const validateWalletAddress = () => {
    const web3 = new Web3(window.ethereum);
    if (web3.utils.isAddress(props.buyData.toWalletAddress)) {
      props.setStep(3);
    } else {
      setIsValidAddress(false);
    }
  }

  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-back-btn-sec" onClick={() => props.setStep(1)}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="buy-title-sec">
            <span>BUY</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
        </div>
        <div className="buy-body-sec">
          <h3>Enter Wallet Address To Recieve The Tokens</h3>
          <Form className="buy-form wallet-form">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Wallet Address"
                value={props.buyData.toWalletAddress}
                onChange={e => {
                  props.setBuyData({
                    ...props.buyData,
                    toWalletAddress: e.target.value
                  })
                  setIsValidAddress(true);
                }}
              />
              {/* <InputGroup.Text id="basic-addon2">
                <Button>
                  <Image src={window.location.origin + "/img/copy.svg"} />
                </Button>
              </InputGroup.Text> */}
            </InputGroup>
            {!isValidAddress ?
              <div className="text-danger">Invalid wallet address</div> : null
            }
            <div className="transaction-or-btn text-center">
              <span>Or</span>
            </div>
            <p className="form-content">
              Connect Metamask To Recieve The Tokens
            </p>
            <div className="meta-btn">
              <WalletConnectorIndex chainId={props.buyData.paymentMethod.id} />
              {/* <ConnectKitButton.Custom>
                {({ isConnected, isConnecting, show, hide, address, ensName }) => {
                  return (
                    <Button onClick={() => show()}>
                      {isConnected ?
                        <>Disconnect</>
                        : <>
                          <span>
                            <Image src={window.location.origin + "/img/metamask.png"} />
                          </span>
                          Connect Metamask
                        </>
                      }
                    </Button>
                  );
                }}
              </ConnectKitButton.Custom> */}
            </div>
            <div className="next-btn">
              <Button
                className="default-btn"
                disabled={!props.buyData.toWalletAddress}
                onClick={() => validateWalletAddress()}>
                Next
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default WalletConnectSec;
