import React, { useState, useEffect } from "react";
import { Row, Container, Col, Image, InputGroup, Form, Button, Table, Modal } from "react-bootstrap";
import "./Transaction.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchWalletTransactionListStart, fetchMoreWalletTransactionListStart } from "../store/actions/TransactionAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import InfiniteScroll from 'react-infinite-scroll-component';
import TransactionViewModal from "./TransactionViewModal";
import NoDataFound from "../helper/NoDataFound";


const TransactionIndex = (props) => {

  const t = useTranslation();
  const [search, setSearch] = useState("");
  const [transactionView, setTransactionView] = useState(false);
  const [paymentId, setPaymentId] = useState('')

  const closeTransactionViewModal = () => {
    setTransactionView(false);
  };

  const searchSubmit = (e) => {
    e.preventDefault();
  }



  const moreDetails = (transaction) => {
    setPaymentId(transaction);
    setTransactionView(true);
  }
  useEffect(() => {
    props.dispatch(
      fetchWalletTransactionListStart()
    );
  }, []);
  const fetchTransactionData = () => {
    if (search === "" || search.length > 2) {
      props.dispatch(
        fetchWalletTransactionListStart({
          search_key: search,
        })
      );
    }
  }
  const clearItem = () => {
    setSearch("")
    props.dispatch(
      fetchMoreWalletTransactionListStart()
    );
  }
  const fetchMoreTransactions = () => {
    props.dispatch(
      fetchMoreWalletTransactionListStart({
        skip: props.walletTransactions.data.wallet_transactions.length,
        take: 12,
        search_key: search,
      })
    );
  };
  return (
    <>
      <div className="transaction-sec sm-padding">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} lg={9} className="text-center">
              <div className="section-title">
                <h2>
                  <Image
                    src={window.location.origin + "/img/coin-img.png"}
                    className="section-coin-icon"
                  />
                  <div className="section-title-info">
                    {t("transaction")}
                    <span>{t("lists")}</span>
                  </div>
                  <Image
                    src={window.location.origin + "/img/coin-img.png"}
                    className="section-coin-icon"
                  />
                </h2>
              </div>
              <div className="transaction-search-sec">
                <Form onSubmit={searchSubmit} className="transaction-search">
                  <InputGroup className="mb-0">
                    <InputGroup.Text id="basic-addon1">
                      <Image
                        src={window.location.origin + "/img/transaction/icons/search.svg"}
                        className="section-coin-icon"
                      />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      placeholder="Search with details"
                      className="detail-search"
                    />
                    <InputGroup.Text id="basic-addon1" className="clear-item">
                      {search
                        ? <Image style={{ cursor: "pointer" }}
                          className="header-search-icon"
                          src={
                            window.location.origin + "/img/close-icon.svg"
                          }
                          onClick={clearItem}
                        />
                        : null
                      }
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="search-btn-sec">
                    <Button className="default-btn" onClick={() => fetchTransactionData()} >
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
          <Row className="text-left">
            <Col md={12}>
              <div className="transaction-table-sec">
                {props.walletTransactions.loading ?
                  (<CommonCenterLoader />)
                  :
                  <InfiniteScroll
                    dataLength={props.walletTransactions.data.wallet_transactions.length}
                    next={fetchMoreTransactions}
                    hasMore={
                      props.walletTransactions.data.wallet_transactions.length <
                      props.walletTransactions.data.total_wallet_transactions}

                    loader={<CommonCenterLoader />}
                  >{props.walletTransactions.data.wallet_transactions.length > 0
                    ?
                    (<Table responsive>
                      <thead>
                        <tr>
                          <th>{t("payement_type")}</th>
                          <th>{t("payment_id")}</th>
                          <th>{t("time")}</th>
                          <th>{t("sent_txt_hash")}</th>
                          <th>{t("receive_txt_hash")}</th>
                          <th>{t("value")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("view_transaction")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.walletTransactions.data.wallet_transactions.map(
                          (transaction, i) =>
                            <tr key={i}>
                              <td>
                                <div className="payment-type-card">
                                  <div className="payment-type-icon-sec">
                                    <Image
                                      src={window.location.origin + "/img/transaction/icons/busdx-icon.png"}
                                      className="payment-type-icon"
                                    />
                                  </div>
                                  <span className="trans_amount">{transaction.amount_type}</span>
                                </div>
                              </td>
                              <td>
                                <Link to="#" className="transaction-link">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>{transaction.payment_id}</Tooltip>
                                    }>
                                    <span>{transaction.payment_id}</span>
                                  </OverlayTrigger>
                                </Link>
                              </td>
                              <td>{transaction.created_at}</td>
                              <td style={{ cursor: "pointer" }}>
                                <Link to="#" className="transaction-link">
                                  {transaction.user_transaction_hash ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>{transaction.user_transaction_hash}</Tooltip>
                                      }
                                    >
                                      <div className="transaction-id-sec">
                                        <span>{transaction.user_transaction_hash}</span>
                                      </div>
                                    </OverlayTrigger>
                                  ) : (
                                    "-"
                                  )}
                                </Link>
                              </td>
                              <td>
                                <Link to="#" className="transaction-link">
                                  {transaction.admin_transaction_hash ?
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>{transaction.admin_transaction_hash}</Tooltip>
                                      }
                                    >
                                      <div className="transaction-id-sec">
                                        <span>{transaction.admin_transaction_hash}</span>
                                      </div>
                                    </OverlayTrigger>
                                    :
                                    ("-")
                                  }
                                </Link>
                              </td>
                              <td>
                                <div className="transaction-value">
                                  {transaction.tokens} <span> {t("bnb")}</span>
                                </div>
                              </td>
                              <td>
                                <div className="completed-btn-sec">
                                  {transaction.status >= 1 ?
                                    <Button className="completed-btn">
                                      {t("completed")}
                                    </Button>
                                    : <Button className="btn btn-warning">
                                      {t("pending")}
                                    </Button>}
                                </div>
                              </td>
                              <td>
                                <Button variant="primary" onClick={() => moreDetails(transaction.payment_id)}>{t("view")}</Button>
                              </td>
                            </tr>
                        )}
                      </tbody>
                    </Table>
                    )
                    : (
                      <NoDataFound />
                    )}
                  </InfiniteScroll>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <TransactionViewModal
        transactionView={transactionView}
        setTransactionView={setTransactionView}
        closeTransactionViewModal={closeTransactionViewModal}
        paymentId={paymentId}
      />
    </>
  );
};

const mapStateToprops = (state) => ({
  walletTransactions: state.transaction.walletTransactions,
});

function mapDispatcToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToprops, mapDispatcToProps)(withTranslation(TransactionIndex))
