import React from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";

const SellSuccessIndex = (props) => {
  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-back-btn-sec" onClick={() => props.setStep(9)}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="buy-title-sec">
            <span>SELL</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
        </div>
        <div className="buy-body-sec">
          <div className="buy-success-img-sec">
            <Image
              src={window.location.origin + "/img/success.gif"}
              className="buy-success-img"
            />
            <h5>
              Payment Recieved Tokens will be added to your account in 2 Hours
            </h5>
            <div className="you-will-be-getting-sec">
                <p>Your Transaction ID -</p>
                <div className="you-will-be-getting-card">
                    <span>5656565655655456</span>
                    <Button>
                      <Image src={window.location.origin + "/img/copy.svg"} />
                    </Button>
                </div>
            </div>
          </div>
          <div className="next-btn">
            <Button className="default-btn" onClick={() => props.setStep(1)}>
              Home
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellSuccessIndex;
