import React, { useEffect, useState } from 'react';
import CounterTimer from '../../helper/CounterTimer';
import { connect } from 'react-redux';
import { Image, Button } from 'react-bootstrap';


const BuyPaymentWaitSec = (props) => {
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    if (!skipRender && !props.buyTokens.loading) {
      if (Object.keys(props.buyTokens.data).length > 0 && props.buyTokens.data.user_payment_status == 1) {
        props.setStep(5);
      } else {
        props.setStep(7);
      }
    }
    setSkipRender(false);
  }, [props.buyTokens]);

  const onTimerCompleted = () => {
    props.setStep(6);
  }

  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-title-sec">
            <span>BUY</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
          <div className="processing-counter">
            <CounterTimer
              time={480000}
              onCompleted={onTimerCompleted}
            />
          </div>

        </div>
        <div className="buy-body-sec buy-body-sec-new">
          <div className="buy-success-img-sec">
            <Image
              src={window.location.origin + "/img/processing.gif"}
              className="buy-processing-img"
            />
            <h5>
              Processing...
            </h5>
          </div>
          
          {/* <div className="next-btn">
            <Button className="default-btn">
              Home
            </Button>
          </div> */}
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => ({
  buyTokens: state.transaction.buyTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(BuyPaymentWaitSec);