import React, { useEffect, useState } from "react";
import { Image, Form, InputGroup, Tab, Button, Row, Nav, Col } from "react-bootstrap";
import Select, { components } from "react-select";
import axios from 'axios';
import configuration from "react-global-configuration";
import { connect } from 'react-redux';
import { amountConversionStart } from "../../store/actions/WalletConnectorAction";

const ExchangeRateSec = (props) => {
  const constants = configuration.get("configData");
  const [skipRender, setSkipRender] = useState(true);

  const options = [
    { section: "CRYPTO PAYMENT", bgColor: "#efcf35", color: "#000" },
    ...constants.chains,
    // { value: "bnb", label: "BNB", icon: "/img/icons/bnb-icon.svg", id: 97 },
    { section: "FIAT PAYMENT", bgColor: "#474cb6", color: "#fff" },
    { value: "usd", label: "USD", icon: "/img/icons/usd-icon.svg", apiId: "usd", isNetwork: true },
  ];

  const { Option } = components;
  const IconOption = props => (
    props.data.section ?
      <div style={{ backgroundColor: props.data.bgColor, color: props.data.color }} className="section-divider">
        {props.data.section}
      </div>
      : <Option {...props} className="select-option">
        {props.data.label}
        <img
          src={props.data.icon}
          style={{ width: 24, height: 24 }}
          alt={props.data.label}
        />
      </Option>
  );

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "8px",
      overflow: "hidden"
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 120,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: 'transparent', border: 'none!important',
      boxShadow: 'none',

    }),
    singleValue: provided => ({ ...provided, color: '#fff' }),
    indicatorContainer: provided => ({ ...provided, color: '#fff!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#fff!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#fff"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000"
      };
    }
    ///.....
  }

  useEffect(() => {
    if (!isNaN(props.buyData.buyTokens)) {
      props.dispatch(amountConversionStart({
        convertFrom: "busdx",
        convertTo: props.buyData.paymentMethod.apiId,
        isNetwork: props.buyData.paymentMethod.isNetwork,
      }));
    }
    props.setBuyData({
      ...props.buyData,
      exchangeValue: 0,
    });
  }, [props.buyData.paymentMethod, props.buyData.buyTokens]);


  useEffect(() => {
    if (!skipRender && !props.amountConversion.loading && Object.keys(props.amountConversion.data).length > 0) {
      if (!isNaN(props.buyData.buyTokens)) {
        props.setBuyData({
          ...props.buyData,
          exchangeValue: Math.round((props.amountConversion.data.amount * props.buyData.buyTokens) * Math.pow(10, 8)) / Math.pow(10, 8),
        });
      } else {
        props.setBuyData({
          ...props.buyData,
          exchangeValue: 0,
        });
      }
    }
    setSkipRender(false);
  }, [props.amountConversion]);

  return (
    <>
      <div className="banner-tab-sec">
        <div className="tab-section">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="buy"
          >
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="buy" className="buy-tab">
                      <div className="tab-title">
                        buy{" "}
                        <span>
                          <svg
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.8128 14.1755L14.6169 7.30141C14.391 6.03203 13.4119 5.07828 12.1853 4.86609V4.50234C12.1853 2.19422 10.3075 0.316406 7.99971 0.316406C5.6919 0.316406 3.81408 2.19422 3.81408 4.50234V4.86703C2.5844 5.08141 1.60783 6.03391 1.38252 7.30141L0.18721 14.1755C0.0278348 15.0792 0.249085 15.9205 0.842835 16.6202C1.43627 17.3202 2.22471 17.6833 3.13721 17.6833H12.8635C13.7847 17.6833 14.5731 17.3202 15.1578 16.6202C15.751 15.9205 15.9725 15.0789 15.8128 14.1755ZM10.9366 4.82109H5.06377V4.50203C5.06377 2.88266 6.38096 1.56547 8.00002 1.56547C9.6194 1.56547 10.9366 2.88297 10.9366 4.50203V4.82109Z"
                              fill="none"
                            />
                          </svg>
                        </span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="sell" className="sell-tab" onClick={() => props.setSection("sell")}>
                      <div className="tab-title">
                        sell{" "}
                        <span>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1_41)">
                              <path
                                d="M19.8034 11.8121C19.5326 11.3313 19.0212 11.0327 18.4688 11.0327C18.2079 11.0327 17.9488 11.1009 17.7197 11.2299L13.2605 13.7412C13.2023 13.774 13.1603 13.8294 13.1443 13.8943C13.0665 14.2117 12.8835 14.655 12.4533 14.9637C11.9773 15.3053 11.3332 15.3765 10.5485 15.1752C10.5437 15.1741 10.5391 15.1727 10.5349 15.1714L7.96256 14.3727C7.80702 14.3244 7.7198 14.1586 7.76808 14.0031C7.80675 13.8787 7.92008 13.7951 8.05011 13.7951C8.07965 13.7951 8.10925 13.7996 8.13787 13.8085L10.6969 14.6031C10.701 14.6043 10.7051 14.6055 10.7093 14.6066C11.3128 14.7589 11.7841 14.7165 12.1088 14.4837C12.519 14.1893 12.5912 13.672 12.5998 13.596C12.6001 13.5935 12.6003 13.5911 12.6005 13.5887C12.6007 13.5874 12.6007 13.5867 12.6008 13.5866C12.6008 13.5858 12.6009 13.5849 12.6009 13.5842C12.601 13.5837 12.601 13.5832 12.601 13.5826C12.6499 12.9447 12.2382 12.3556 11.6214 12.1814L11.61 12.178C11.5912 12.1718 9.69819 11.5535 8.49204 11.2094C8.35803 11.1712 7.98878 11.0823 7.45474 11.0823C6.70785 11.0823 5.59364 11.2593 4.52493 12.1041L0.0896849 15.6918C-0.0117728 15.7738 -0.0297656 15.9215 0.0489729 16.0256L2.45044 19.1977C2.48938 19.249 2.54717 19.2827 2.61103 19.2913C2.62172 19.2928 2.63241 19.2935 2.64315 19.2935C2.69616 19.2935 2.7481 19.276 2.79059 19.2433L5.88384 16.8615C5.95334 16.8081 6.04652 16.7879 6.13192 16.8081L11.128 17.9887C11.4985 18.0763 11.8989 18.0198 12.2303 17.833L19.2211 13.8958C19.9561 13.4818 20.2173 12.5471 19.8034 11.8121Z"
                                fill="none"
                              />
                              <path
                                d="M17.025 0.706543H7.89431C7.59772 0.706543 7.35721 0.947002 7.35721 1.24364V3.68765C7.35721 3.98429 7.59772 4.22475 7.89431 4.22475H8.09491V9.41434C8.09491 9.71098 8.33537 9.95144 8.63201 9.95144H16.287C16.5837 9.95144 16.8241 9.71098 16.8241 9.41434V4.22469H17.0249C17.3215 4.22469 17.562 3.98423 17.562 3.68759V1.24364C17.5621 0.947002 17.3215 0.706543 17.025 0.706543ZM13.5894 5.50078H11.3297C11.0928 5.50078 10.9001 5.30802 10.9001 5.0711C10.9001 4.83419 11.0928 4.64143 11.3297 4.64143H13.5894C13.8263 4.64143 14.0191 4.83419 14.0191 5.0711C14.0191 5.30802 13.8263 5.50078 13.5894 5.50078ZM16.4879 3.1505H16.2871H8.63201H8.4314V1.78074H16.4879V3.1505Z"
                                fill="none"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1_41">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="none"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="buy">
                    <div className="buy-body-sec">
                      <div className="tab-bottom-content">
                        {/* <h3>
                                                        Publishing and graphic design, Lorem ipsum is
                                                        a placeholder text commonly used{" "}
                                                    </h3> */}
                        <Form className="buy-form">
                          <Form.Label>You Want To Buy</Form.Label>
                          <InputGroup className="">
                            <Form.Control
                              placeholder="Please enter token"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              value={props.buyData.buyTokens}
                              onChange={e => props.setBuyData({
                                ...props.buyData,
                                buyTokens: e.target.value,
                              })}
                            />
                            <InputGroup.Text id="basic-addon2">
                              <div className="input-group-btn">
                                PSCN{" "}
                                <span>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/img/coin.png"
                                    }
                                    className="coin-img coin-icon"
                                  />
                                </span>
                              </div>
                            </InputGroup.Text>
                          </InputGroup>

                          <div className="line-vertical">
                          </div>
                          {props.buyData.buyTokens < 100 && (
                            <div className="min-text mb-2">
                              <p className="text-danger">Min 100 token</p>
                            </div>
                          )}
                          <Form.Label>You Pay</Form.Label>
                          <InputGroup className="mb-4 background-grey">
                            <Form.Control
                              placeholder="0"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              disabled="disabled"
                              value={props.buyData.exchangeValue}
                            />
                            <InputGroup.Text id="basic-addon2" className="select-input-text">
                              <Select
                                defaultValue={options[0]}
                                options={options}
                                components={{ Option: IconOption }}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={customStyles}
                                isSearchable={false}
                                value={props.buyData.paymentMethod}
                                onChange={value => props.setBuyData({
                                  ...props.buyData,
                                  paymentMethod: value
                                })}
                              // menuIsOpen={true}
                              />
                            </InputGroup.Text>
                          </InputGroup>
                          <div className="sell-value-denomiantion">
                            <h4>1 PSCN
                              <span>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/img/coin.png"
                                  }
                                  className="denomination-icon"
                                />
                              </span>
                            </h4>
                            <span>
                              =
                            </span>
                            <h4>{`${props.amountConversion.data.amount
                              ? Math.round(props.amountConversion.data.amount * Math.pow(10, 8)) / Math.pow(10, 8)
                              : "..."
                              } ${props.buyData.paymentMethod.label}`}
                              <span>
                                <Image
                                  src={props.buyData.paymentMethod.icon}
                                  className="denomination-icon"
                                />
                              </span>
                            </h4>
                          </div>
                          <div className="next-btn">
                            <Button
                              disabled={
                                props.buyData.buyTokens < 100 ||
                                isNaN(props.buyData.buyTokens) ||
                                props.amountConversion.buttonDisable
                              }
                              className="default-btn"
                              onClick={() => props.setStep(2)}>
                              Next
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
}


const mapStateToProps = (state) => ({
  amountConversion: state.walletConnector.amountConversion,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRateSec);