import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage
} from "../../helper/ToastNotification";
import {
  BUY_TOKENS_START,
  FETCH_WALLET_TRANSACTION_DETAILS_START,
  FETCH_WALLET_TRANSACTION_LIST_START,
  FETCH_MORE_WALLET_TRANSACTION_LIST_START ,
  SELL_TOKENS_START,
  STRIPE_PAYMENT_START
} from "../actions/ActionConstant";
import {
  buyTokensFailure,
  buyTokensSuccess,
  fetchWalletTransactionDetailsFailure,
  fetchWalletTransactionDetailsSuccess,
  fetchWalletTransactionListFailure,
  fetchWalletTransactionListSuccess,
  sellTokensFailure,
  sellTokensSuccess,
  stripePaymentFailure,
  stripePaymentSuccess
} from "../actions/TransactionAction";

function* buyTokensAPI(action) {
  try {
    const response = yield api.postMethod("buy_tokens", action.data);

    if (response.data.success) {
      yield put(buyTokensSuccess(response.data.data));
    } else {
      yield put(buyTokensFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(buyTokensFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* sellTokensAPI(action) {
  try {
    const response = yield api.postMethod("sell_tokens", action.data);

    if (response.data.success) {
      yield put(sellTokensSuccess(response.data.data));
    } else {
      yield put(sellTokensFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(sellTokensFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchWalletTransactionListAPI(action) {
  try {
    const response = yield api.postMethod("wallet_transactions_list", action.data);

    if (response.data.success) {
      yield put(fetchWalletTransactionListSuccess(response.data.data));
    } else {
      yield put(fetchWalletTransactionListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchWalletTransactionListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchWalletTransactionDetailsAPI(action) {
  try {
    const response = yield api.postMethod("wallet_transaction_view", action.data);

    if (response.data.success) {
      yield put(fetchWalletTransactionDetailsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchWalletTransactionDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchWalletTransactionDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* stripePaymentAPI(action) {
  try {
    const response = yield api.postMethod("token_payment_by_stripe", action.data);

    if (response.data.success) {
      yield put(stripePaymentSuccess(response.data.data));
    } else {
      yield put(stripePaymentFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(stripePaymentFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


export default function* TransactionSaga() {
  yield all([
    yield takeLatest(BUY_TOKENS_START, buyTokensAPI),
    yield takeLatest(SELL_TOKENS_START, sellTokensAPI),
    yield takeLatest(FETCH_WALLET_TRANSACTION_LIST_START, fetchWalletTransactionListAPI),
    yield takeLatest(FETCH_MORE_WALLET_TRANSACTION_LIST_START , fetchWalletTransactionListAPI),
    yield takeLatest(FETCH_WALLET_TRANSACTION_DETAILS_START, fetchWalletTransactionDetailsAPI),
    yield takeLatest(STRIPE_PAYMENT_START, stripePaymentAPI),
  ])
} 