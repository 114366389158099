import React, { useState, useEffect } from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import "../Exchange.css";
import { connect } from "react-redux";

const BankDetails = (props) => {

  const validateData = () => {
    if (props.sellData.bankDetails.first_name &&
      props.sellData.bankDetails.last_name &&
      props.sellData.bankDetails.account_number &&
      props.sellData.bankDetails.route_number &&
      props.sellData.bankDetails.bank_type) {
      props.setStep(3);
    }
  }

  return (
    <>
      <div className="sell-sec">
        <div className="sell-header-sec">
          <div className="sell-back-btn-sec" onClick={() => props.setStep(1)}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="sell-title-sec">
            <span>SELL</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="sell-icon"
            />
          </div>
        </div>
        <div className="sell-body-sec">
          <h3>Enter Bank Details</h3>
          <Form className="sell-form">
            <Form.Group className="mb-3 transaction-form-control">
              <Form.Control
                type="text"
                placeholder="First Name *"
                value={props.sellData.bankDetails.first_name}
                onChange={e => props.setSellData({
                  ...props.sellData,
                  bankDetails: {
                    ...props.sellData.bankDetails,
                    first_name: e.target.value,
                  }
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3 transaction-form-control">
              <Form.Control
                type="text"
                placeholder="Last Name *"
                value={props.sellData.bankDetails.last_name}
                onChange={e => props.setSellData({
                  ...props.sellData,
                  bankDetails: {
                    ...props.sellData.bankDetails,
                    last_name: e.target.value,
                  }
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3 transaction-form-control">
              <Form.Control
                type="text"
                placeholder="Account Number *"
                value={props.sellData.bankDetails.account_number}
                onChange={e => props.setSellData({
                  ...props.sellData,
                  bankDetails: {
                    ...props.sellData.bankDetails,
                    account_number: e.target.value,
                  }
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3 transaction-form-control">
              <Form.Control
                type="text"
                placeholder="Route Number *"
                value={props.sellData.bankDetails.route_number}
                onChange={e => props.setSellData({
                  ...props.sellData,
                  bankDetails: {
                    ...props.sellData.bankDetails,
                    route_number: e.target.value,
                  }
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3 transaction-form-control">
              <Form.Select
                value={props.sellData.bankDetails.bank_type}
                onChange={e => props.setSellData({
                  ...props.sellData,
                  bankDetails: {
                    ...props.sellData.bankDetails,
                    bank_type: e.target.value,
                  }
                })}>
                <option value="savings">Savings</option>
                <option value="checking">Checking</option>
              </Form.Select>
              {/* <Form.Control
                type="text"
                placeholder="IFSC Code"
                value={props.sellData.bankDetails.ifsc_code}
                onChange={e => props.setSellData({
                  ...props.sellData,
                  bankDetails: {
                    ...props.sellData.bankDetails,
                    ifsc_code: e.target.value,
                  }
                })}
              /> */}
            </Form.Group>
            <Form.Group className="mb-3 transaction-form-control">
              <Form.Control
                type="text"
                placeholder="Business Name"
                value={props.sellData.bankDetails.business_name}
                onChange={e => props.setSellData({
                  ...props.sellData,
                  bankDetails: {
                    ...props.sellData.bankDetails,
                    business_name: e.target.value,
                  }
                })}
              />
            </Form.Group>
            <div className="next-btn">
              <Button className="default-btn" onClick={() => validateData()}>
                Next
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sellTokens: state.transaction.sellTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);
