import React, { useState, useEffect } from 'react';
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import axios from 'axios';
import configuration from "react-global-configuration";
import { connect } from 'react-redux';
import { amountConversionStart } from '../../store/actions/WalletConnectorAction';


const PaymentSelector = (props) => {
  const constants = configuration.get("configData");
  const [singlePrice, setSinglePrice] = useState();
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    // getPrice();
    if (!isNaN(props.sellData.sellTokens)) {
      props.dispatch(amountConversionStart({
        convertFrom: "busdx",
        convertTo: props.sellData.paymentMethod.apiId,
        isNetwork: props.sellData.paymentMethod.isNetwork,
      }));
    }
    props.setSellData({
      ...props.sellData,
      exchangeValue: 0,
    });
  }, [props.sellData.paymentMethod, props.sellData.sellTokens]);

  useEffect(() => {
    if (!skipRender && !props.amountConversion.loading && Object.keys(props.amountConversion.data).length > 0) {
      if (!isNaN(props.sellData.sellTokens)) {
        props.setSellData({
          ...props.sellData,
          exchangeValue: Math.round((props.amountConversion.data.amount * props.sellData.sellTokens) * Math.pow(10, 8)) / Math.pow(10, 8),
        });
      } else {
        props.setSellData({
          ...props.sellData,
          exchangeValue: 0,
        });
      }
    }
    setSkipRender(false);
  }, [props.amountConversion]);

  return (
    <>
      <div className="sell-sec">
        <div className="sell-header-sec">
          <div className="sell-back-btn-sec" onClick={() => props.setSection("buy")}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="sell-title-sec">
            <span>SELL</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="sell-icon"
            />
          </div>
        </div>
        <div className="sell-body-sec">
          {/* <h3>Publishing and graphic design, Lorem ipsum is a placeholder text commonly used</h3> */}
          <Form className="sell-form">
            <Form.Label>You Want To Sell</Form.Label>
            <InputGroup className="">
              <Form.Control
                placeholder="Please enter token"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={props.sellData.sellTokens}
                onChange={e => props.setSellData({
                  ...props.sellData,
                  sellTokens: e.target.value,
                })}
              />
              <InputGroup.Text id="basic-addon2">
                <div className="input-group-btn">
                  PSCN{" "}
                  <span>
                    <Image
                      src={
                        window.location.origin +
                        "/img/coin.png"
                      }
                      className="coin-img coin-icon"
                    />
                  </span>
                </div>
              </InputGroup.Text>
            </InputGroup>
            <div className="sell-radio-wrapper">
              <div className="sell-radio-left-sec">
                {constants.chains.map((chain, i) =>
                  <div className="inputGroup" key={i}>
                    <input id="radio1" name="radio" type="radio"
                      checked={props.sellData.paymentMethod === chain}
                      onChange={() => { }}
                    />
                    <label for="radio1" onClick={() => props.setSellData({
                      ...props.sellData,
                      paymentMethod: chain
                    })}>
                      <Image
                        src={chain.icon}
                        className="sell-radio-icon"
                      />
                      <span>{chain.symbol}</span>
                    </label>
                  </div>
                )}
              </div>
              {/* <div className="sell-radio-right-sec">
                <div className="inputGroup">
                  <input id="radio4" name="radio" type="radio"
                    checked={props.sellData.paymentMethod === "USD"}
                    onChange={() => { }} />
                  <label for="radio4" onClick={() => props.setSellData({
                    ...props.sellData,
                    paymentMethod: "USD"
                  })}
                  // onClick={() => props.selectBank()}
                  >
                    <Image
                      src={
                        window.location.origin +
                        "/img/sell/icons/add-bank.svg"
                      }
                      className="sell-radio-icon"
                    />
                    <span>BANK</span>
                  </label>
                </div>
              </div> */}
            </div>
            <div className="you-will-be-getting-sec">
              <p>You Will Be Getting</p>
              <div className="you-will-be-getting-card">
                <span>{`${props.sellData.exchangeValue} ${props.sellData.paymentMethod.symbol}`} </span>
                <Image
                  src={props.sellData.paymentMethod.icon}
                  className="you-will-be-getting-icon"
                />
              </div>
            </div>
            <div className="sell-value-denomiantion">
              <h4>1 PSCN
                <span>
                  <Image
                    src={
                      window.location.origin +
                      "/img/coin.png"
                    }
                    className="denomination-icon"
                  />
                </span>
              </h4>
              <span>
                =
              </span>
              <h4>{`${props.amountConversion.data.amount
                ? Math.round(props.amountConversion.data.amount * Math.pow(10, 8)) / Math.pow(10, 8)
                : "..."
                } ${props.sellData.paymentMethod.symbol}`}
                <span>
                  {/* <Image
                    src={
                      window.location.origin +
                      "/img/sell/bnb-white.png"
                    }
                    className="denomination-icon"
                  /> */}
                </span>
              </h4>
            </div>
            <div className="next-btn">
              <Button
                className="default-btn"
                disabled={
                  props.sellData.sellTokens < 100 ||
                  isNaN(props.sellData.sellTokens) ||
                  props.amountConversion.buttonDisable
                }
                onClick={() => {
                  props.setStep(2)
                }}>
                Sell Now
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  amountConversion: state.walletConnector.amountConversion,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSelector);