import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";

const MainLayout = (props) => {

  return (
    <>
      <HeaderIndex/>
        <div className="main-wrapper">
            <ToastContainer />
            {props.children}
        </div>
      <FooterIndex/>
    </>
  );
};

export default MainLayout;
