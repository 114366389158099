import React, { useEffect, useState } from 'react';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';
import { setWalletDetails } from '../store/actions/WalletConnectorAction';
import WalletConnectorModal from './WalletConnectorModal';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

const NetWorkConnected = ({ className, chainId, dispatch, ...props }) => {

  const network = useNetwork();

  const { disconnect } = useDisconnect({
    onError(error) {
      console.log('Error', error)
    },
    onMutate(args) {
      console.log('Mutate', args)
    },
    onSettled(data, error) {
      console.log('Settled', { data, error })
    },
    onSuccess(data) {
      dispatch(setWalletDetails());
    },
  });

  useEffect(() => {
    if (chainId && chainId !== network.chain?.id) {
      disconnect();
    }
  }, [network.chain?.id]);

  return (
    <>
      <Button className={className} onClick={() => disconnect()} disabled={props.disabled}>
        Disconnect
      </Button>
    </>
  );
}

const WalletConnectorIndex = ({ className = "", chainId = null, dispatch, ...props }) => {

  const [show, setShow] = useState(false);

  const hideConnectorModal = () => {
    setShow(false);
  }

  const account = useAccount({
    onConnect(walletAccount) {
      dispatch(setWalletDetails(walletAccount));
    },
  });

  return (
    <>
      {account.address ?
        <NetWorkConnected className={className} chainId={chainId} dispatch={dispatch} disabled={props.disabled} />
        : <Button
          className={className}
          onClick={e => setShow(true)}
          disabled={props.disabled}
        >
          Connect to Wallet
        </Button>
      }
      {show ?
        <WalletConnectorModal
          show={show}
          hideConnectorModal={hideConnectorModal}
          chainId={chainId}
        />
        : null
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  connectedWallet: state.walletConnector.connectedWallet,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(WalletConnectorIndex);