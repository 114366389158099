import React, { useState, useEffect } from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import {
  useAccount,
  useEnsAvatar,
  useNetwork,
} from "wagmi";
// import { ConnectKitButton } from "connectkit";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { sellTokensStart } from "../../store/actions/TransactionAction";
import SellPaymentBtn from "./SellPaymentBtn";
import WalletConnectorIndex from "../../WalletConnector/WalletConnectorIndex";

const SellSection = (props) => {
  const constants = configuration.get("configData");
  const account = useAccount();

  const [skipRender, setSkipRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const copy = () => {
    props.dispatch(getSuccessNotificationMessage("Wallet address copied"))
  }

  const onValidateTransactionId = e => {
    e.preventDefault();
    if (props.sellData.paymentMethod.symbol === "USD") {
      props.dispatch(sellTokensStart({
        net_id: 97,
        tokens: props.sellData.sellTokens,
        amount: props.sellData.exchangeValue,
        amount_type: props.sellData.paymentMethod.symbol,
        user_transaction_hash: props.sellData.transactionId,
        admin_payment_type: "BANK_TRANSFER",
        ...props.sellData.bankDetails,
      }));
    } else {
      props.dispatch(sellTokensStart({
        net_id: 97,
        tokens: props.sellData.sellTokens,
        amount: props.sellData.exchangeValue,
        amount_type: props.sellData.paymentMethod.symbol,
        to_wallet_address: props.sellData.toWalletAddress,
        wallet_address: props.sellData.toWalletAddress,
        admin_payment_type: "CRYPTO",
        user_transaction_hash: props.sellData.transactionId,
      }));
    }
    props.setStep(4)
  }

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    if (!skipRender && !props.sellTokens.loading && Object.keys(props.sellTokens.data).length > 0) {
      props.setStep(4);
    }
    setSkipRender(false);
  }, [props.sellTokens]);

  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-back-btn-sec" onClick={() => props.setStep(2)}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="buy-title-sec">
            <span>SELL</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
        </div>
        <div className="buy-body-sec buy-body-sec-new">
          <div className="wallet-scan mb-3">
            <Image src={window.location.origin + "/img/scan.png"} className="qr-scan-img" />
          </div>
          <div className="you-will-be-getting-card mb-3">
            <span>
              {props.sellData.sellTokens} {"PSCN"}
            </span>
          </div>
          <div className="buy-scan-copy-sec">
            <div className="buy-scan-hash-id">
              <span value={constants.admin_wallet_address}>
                {`
                  ${constants.admin_wallet_address.substring(0, 12)}
                  ...
                  ${constants.admin_wallet_address.substring(
                  constants.admin_wallet_address.length - 7,
                  constants.admin_wallet_address.length)}
                  `}
              </span>
              <CopyToClipboard text={constants.admin_wallet_address}
                onCopy={() => copy()}
              >
                <Button>
                  <Image src={window.location.origin + "/img/copy.svg"} />
                </Button>
              </CopyToClipboard>
            </div>
          </div>
          <Form className="buy-form card-form wallet-form" onSubmit={onValidateTransactionId}>
            <div className="paste-hash-code-sec">
              <div className="paste-hase-code-input">
                <InputGroup className="mb-0">
                  <Form.Control
                    type="text"
                    placeholder="Paste Your Transaction ID"
                    value={props.sellData.transactionId}
                    onChange={e => props.setSellData({
                      ...props.sellData,
                      transactionId: e.target.value
                    })}
                    disabled={isLoading}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <Button onClick={() => props.setSellData({
                      ...props.sellData,
                      transactionId: ""
                    })}>
                      <Image src={window.location.origin + "/img/close-icon.svg"} />
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="paste-hash-code-btn">
                <Button
                  type="submit"
                  className="validate-btn"
                  disabled={!props.sellData.transactionId || props.sellTokens.buttonDisable || isLoading}
                >
                  {
                    props.sellTokens.loadingButtonContent
                      ? props.sellTokens.loadingButtonContent
                      : "Validate"
                  }
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="buy-footer-sec">
          <div className="or-divider">Or</div>
          {account.address ?
            <SellPaymentBtn
              sellData={props.sellData}
              setSellData={props.setSellData}
              setStep={props.setStep}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            : null
          }
          <div className="scan-btn-sec">
            {account.address ?
              <>
                <div className="scan-details meta-btn">
                  <Button>
                    <span>
                    </span>
                    {`${account.address.substring(0, 5)}....
                    ${account.address.substring(account.address.length - 4, account.address.length)}`}
                  </Button>
                </div>
                <div className="disconnect-btn-sec">
                  <WalletConnectorIndex
                    className="disconnect-btn"
                    chainId={Number(constants.binance_network_id)}
                    disabled={isLoading} />
                </div>
              </>
              :
              <div className="scan-details meta-btn">
                <WalletConnectorIndex
                  chainId={Number(constants.binance_network_id)}
                  disabled={isLoading} />
              </div>
            }
          </div>
        </div>
      </div >
    </>
  );
};

const mapStateToProps = (state) => ({
  sellTokens: state.transaction.sellTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(SellSection);
