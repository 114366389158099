import { combineReducers } from "redux";
import TransactionReducer from "./TransactionReducer";
import WalletConnectorReducer from "./WalletConnectorReducer";
import HomePageReducer from "./HomePageReducer";
import UserReducer from "./UserReducer";

export default combineReducers({
    transaction: TransactionReducer,
    walletConnector: WalletConnectorReducer,
    homePage:HomePageReducer,
    users: UserReducer,
}) 