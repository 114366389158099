import React, { useEffect, useState } from "react";
import { Row, Container, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  updateUserDetailsStart,
  kycApplicantStart,
} from "../store/actions/UserAction";
import { connect } from "react-redux";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import ProfileLoader from "../helper/ProfileLoader";
import KYCUpdateModal from "../KYC/KYCUpdateModal";

const ProfileIndex = (props) => {
  const [imgPreviewAdd, setImgPreviewAdd] = useState(null);
  const [kyc, setKyc] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const closeKycModal = () => {
    setKyc(false);
  };

  const imgPreview = (e) => {
    setImgPreviewAdd(URL.createObjectURL(e.target.files[0]));
    props.dispatch(updateUserDetailsStart({ picture: e.target.files[0] }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.kycStatusUpdate.loading &&
      Object.keys(props.kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
    }
    setSkipRender(false);
  }, [props.kycStatusUpdate]);

  const verifyKyc = (e) => {
    e.preventDefault();
    props.dispatch(kycApplicantStart());
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.kycApplicant.loading &&
      Object.keys(props.kycApplicant.data).length > 0
    ) {
      setKyc(true);
    }
    setSkipRender(false);
  }, [props.kycApplicant]);

  return (
    <>
      {props.profile.loading ? (
        <ProfileLoader />
      ) : (
        <>
          <div className="profile-sec">
            <div className="profile-box">
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={12} xl={8} lg={9}>
                    <div className="profile-header-sec">
                      <div className="profile-header-left-sec">
                        <div className="profile-img-change-sec">
                          <CustomLazyLoad
                            src={
                              imgPreviewAdd
                                ? imgPreviewAdd
                                : props.profile.data.picture
                            }
                            placeholderSrc={
                              window.location.origin +
                              "/images/placeholder.jpeg"
                            }
                            classes="profile-image"
                          />
                          <label className="profile-img-edit-icon">
                            <input
                              type="file"
                              accept="image/png, image/jpeg,, image/jpg"
                              size="60"
                              onChange={(e) => imgPreview(e)}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                              width="35"
                              height="35"
                              fill="#fff"
                            >
                              <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                            </svg>
                          </label>
                        </div>
                        <div className="profile-user-info">
                          <h4>{props.profile.data.name}</h4>
                          {props.profile.data.mobile && (
                            <h5>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 20 21"
                              >
                                <path
                                  stroke="#757575"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.3"
                                  d="M7.693 4.896a11.748 11.748 0 01-.43-2.137C7.188 2.037 6.558 1.5 5.832 1.5H2.463a1.47 1.47 0 00-1.457 1.61c.768 8.693 7.691 15.616 16.383 16.384A1.469 1.469 0 0019 18.04v-3.003c0-1.096-.537-1.724-1.26-1.8-.723-.077-1.439-.22-2.136-.43a2.39 2.39 0 00-2.375.607l-1.441 1.441a15.244 15.244 0 01-6.142-6.142l1.441-1.441a2.39 2.39 0 00.606-2.375z"
                                ></path>
                              </svg>
                              <span>{props.profile.data.mobile}</span>
                            </h5>
                          )}
                          {props.profile.data.email && (
                            <h5>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="none"
                                viewBox="0 0 20 13"
                              >
                                <path
                                  stroke="#757575"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.3"
                                  d="M18.59 1.367L10 7.355 1.41 1.367m.512-.355a.979.979 0 00-.652.242.785.785 0 00-.27.584v9.336c0 .22.097.43.27.584a.979.979 0 00.652.242h16.156a.979.979 0 00.652-.242.785.785 0 00.27-.584V1.826a.785.785 0 00-.27-.584.979.979 0 00-.652-.242H1.922v.012z"
                                ></path>
                              </svg>
                              <span>{props.profile.data.email}</span>
                            </h5>
                          )}
                          {props.profile.data.kyc_verified && (
                            <h5>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M96 352V356C96 363.426 98.95 370.548 104.201 375.799C109.452 381.05 116.574 384 124 384H228C235.426 384 242.548 381.05 247.799 375.799C253.05 370.548 256 363.426 256 356V352C256.321 338.195 252.049 324.675 243.853 313.562C235.658 302.449 224.003 294.372 210.72 290.6C218.555 283.572 224.074 274.333 226.546 264.102C229.018 253.871 228.327 243.131 224.564 233.301C220.802 223.471 214.145 215.015 205.474 209.049C196.803 203.083 186.525 199.889 176 199.889C165.475 199.889 155.197 203.083 146.526 209.049C137.855 215.015 131.198 223.471 127.436 233.301C123.673 243.131 122.983 253.871 125.454 264.102C127.926 274.333 133.445 283.572 141.28 290.6C127.997 294.372 116.342 302.449 108.147 313.562C99.9513 324.675 95.6787 338.195 96 352ZM176 216C183.12 216 190.08 218.111 196.001 222.067C201.921 226.023 206.535 231.645 209.26 238.223C211.984 244.802 212.697 252.04 211.308 259.023C209.919 266.007 206.491 272.421 201.456 277.456C196.421 282.491 190.007 285.919 183.023 287.308C176.04 288.697 168.802 287.984 162.223 285.26C155.645 282.535 150.023 277.921 146.067 272.001C142.111 266.08 140 259.12 140 252C140 242.452 143.793 233.295 150.544 226.544C157.296 219.793 166.452 216 176 216ZM160 304H192C198.342 303.858 204.648 305.002 210.536 307.364C216.423 309.726 221.772 313.257 226.258 317.743C230.743 322.228 234.274 327.577 236.636 333.464C238.998 339.352 240.142 345.658 240 352V356C240 359.183 238.736 362.235 236.485 364.485C234.235 366.736 231.183 368 228 368H124C120.817 368 117.765 366.736 115.515 364.485C113.264 362.235 112 359.183 112 356V352C111.858 345.658 113.002 339.352 115.364 333.464C117.726 327.577 121.257 322.228 125.743 317.743C130.228 313.257 135.577 309.726 141.464 307.364C147.352 305.002 153.658 303.858 160 304Z"
                                  fill="#757582"
                                />
                                <path
                                  d="M424 296C421.878 296 419.843 296.843 418.343 298.343C416.843 299.843 416 301.878 416 304V404C416 407.183 414.736 410.235 412.485 412.485C410.235 414.736 407.183 416 404 416H76C72.8174 416 69.7652 414.736 67.5147 412.485C65.2643 410.235 64 407.183 64 404V172C64 168.817 65.2643 165.765 67.5147 163.515C69.7652 161.264 72.8174 160 76 160H252C254.122 160 256.157 159.157 257.657 157.657C259.157 156.157 260 154.122 260 152C260 149.878 259.157 147.843 257.657 146.343C256.157 144.843 254.122 144 252 144H76C68.5739 144 61.452 146.95 56.201 152.201C50.95 157.452 48 164.574 48 172V404C48 411.426 50.95 418.548 56.201 423.799C61.452 429.05 68.5739 432 76 432H404C411.426 432 418.548 429.05 423.799 423.799C429.05 418.548 432 411.426 432 404V304C432 301.878 431.157 299.843 429.657 298.343C428.157 296.843 426.122 296 424 296Z"
                                  fill="#757582"
                                />
                                <path
                                  d="M417 146.36L366.68 196.68L335 165C333.499 163.499 331.463 162.656 329.34 162.656C327.217 162.656 325.181 163.499 323.68 165C322.179 166.501 321.335 168.537 321.335 170.66C321.335 172.783 322.179 174.819 323.68 176.32L361 213.64C361.744 214.389 362.629 214.983 363.604 215.388C364.579 215.793 365.624 216.001 366.68 216C368.798 215.991 370.827 215.142 372.32 213.64L428.32 157.64C429.696 156.116 430.434 154.121 430.379 152.068C430.325 150.015 429.484 148.061 428.029 146.612C426.574 145.162 424.618 144.328 422.565 144.281C420.512 144.234 418.519 144.978 417 146.36Z"
                                  fill="#757582"
                                />
                                <path
                                  d="M473.12 101.88C472.922 100.032 472.088 98.3114 470.759 97.0122C469.431 95.713 467.692 94.9167 465.84 94.76C450.581 92.7862 435.683 88.6375 421.6 82.44C407.054 76.3828 393.502 68.1708 381.4 58.08C379.925 56.7308 377.999 55.9825 376 55.9825C374.001 55.9825 372.075 56.7308 370.6 58.08C358.501 68.1753 344.949 76.3877 330.4 82.44C316.392 88.6114 301.577 92.7596 286.4 94.76C284.541 94.9078 282.793 95.7002 281.456 97.0002C280.12 98.3002 279.279 100.026 279.08 101.88C278.72 105.28 270.72 185.16 290.48 224.6C314.12 271.72 370.48 298.16 372.84 299.28C373.893 299.767 375.04 300.02 376.2 300.02C377.361 300.02 378.507 299.767 379.56 299.28C381.92 298.16 438.28 271.72 461.92 224.6C481.48 185.16 473.48 105.28 473.12 101.88ZM447.4 217.44C429.44 253.32 388 276.8 376 283.08C364 276.8 322.56 253.32 304.6 217.44C289.88 188 292.84 129.08 294.28 109.8C308.859 107.26 323.09 103.017 336.68 97.16C350.689 91.2356 363.899 83.5756 376 74.36C388.101 83.5756 401.311 91.2356 415.32 97.16C428.91 103.017 443.141 107.26 457.72 109.8C459.2 129.08 462.16 188 447.4 217.44Z"
                                  fill="#757582"
                                />
                                <path
                                  d="M296 384H376C378.122 384 380.157 383.157 381.657 381.657C383.157 380.157 384 378.122 384 376C384 373.878 383.157 371.843 381.657 370.343C380.157 368.843 378.122 368 376 368H296C293.878 368 291.843 368.843 290.343 370.343C288.843 371.843 288 373.878 288 376C288 378.122 288.843 380.157 290.343 381.657C291.843 383.157 293.878 384 296 384Z"
                                  fill="#757582"
                                />
                                <path
                                  d="M296 336H344C346.122 336 348.157 335.157 349.657 333.657C351.157 332.157 352 330.122 352 328C352 325.878 351.157 323.843 349.657 322.343C348.157 320.843 346.122 320 344 320H296C293.878 320 291.843 320.843 290.343 322.343C288.843 323.843 288 325.878 288 328C288 330.122 288.843 332.157 290.343 333.657C291.843 335.157 293.878 336 296 336Z"
                                  fill="#757582"
                                />
                              </svg>

                              <span>KYC Verified</span>
                            </h5>
                          )}
                          {/* <h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 17 20"
                        >
                          <path
                            stroke="#757575"
                            strokeWidth="1.3"
                            d="M.65 8.334C.65 3.678 4.348.65 8.5.65c4.152 0 7.85 3.028 7.85 7.684 0 1.44-.581 3.072-1.837 4.91-1.253 1.836-3.152 3.838-5.718 6.006a.468.468 0 01-.58 0c-2.57-2.168-4.472-4.17-5.727-6.006C1.23 11.406.65 9.774.65 8.334zm5.075-.203c0 1.504 1.275 2.683 2.775 2.683s2.775-1.179 2.775-2.683c0-1.504-1.275-2.683-2.775-2.683s-2.775 1.18-2.775 2.683z"
                          ></path>
                        </svg>
                        <span>sunil1221@gmail.com</span>
                      </h5> */}
                        </div>
                      </div>
                      <div className="profile-header-right-sec">
                        <Link to="/edit-profile" className="default-btn">
                          Edit
                        </Link>
                      </div>
                      {!props.profile.data.kyc_verified && (
                        <div className="profile-header-right-sec">
                          <Button onClick={verifyKyc} className="default-btn" disabled={props.kycApplicant.buttonDisable}>
                            {props.kycApplicant.loadingButtonContent
                              ? props.kycApplicant.loadingButtonContent
                              : " Verify KYC"}
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  kycStatusUpdate: state.users.kycStatusUpdate,
  kycApplicant: state.users.kycApplicant,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);
