import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import configuration from "react-global-configuration";

const FooterIndex = (props) => {
  return (
    <>
      <footer className="footer-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="footer-card">
                <div className="footer-logo">
                  <Image
                    className="logo"
                    src={configuration.get("configData.site_logo")}
                  />
                </div>
                <div className="footer-copyrights">
                  <p>Copyright © 2022 All Rights Reserved.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default FooterIndex;
