import React from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import "../Exchange.css";

const SellIndex = (props) => {
    return (
        <>
            <div className="sell-sec">
                <div className="sell-header-sec">
                    <div className="sell-back-btn-sec" onClick={() => props.setStep(1)}>
                        <Image
                            src={window.location.origin + "/img/buy/back-arrow.svg"}
                            className="back-arrow-icon"
                        />
                        <span>Back</span>
                    </div>
                    <div className="sell-title-sec">
                        <span>SELL</span>
                        <Image
                            src={window.location.origin + "/img/buy/buy-icon.svg"}
                            className="sell-icon"
                        />
                    </div>
                </div>
                <div className="sell-body-sec">
                    {/* <h3>Publishing and graphic design, Lorem ipsum is a placeholder text commonly used</h3> */}
                    <Form className="sell-form">
                        <Form.Label>You Want To Sell</Form.Label>
                        <InputGroup className="">
                            <Form.Control
                                placeholder="960"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Text id="basic-addon2">
                                <div className="input-group-btn">
                                    PSCN{" "}
                                    <span>
                                        <Image
                                            src={
                                                window.location.origin +
                                                "/img/coin.png"
                                            }
                                            className="coin-img"
                                        />
                                    </span>
                                </div>
                            </InputGroup.Text>
                        </InputGroup>
                        <div className="sell-radio-wrapper">
                            <div className="sell-radio-left-sec">
                                <div className="inputGroup">
                                    <input id="radio1" name="radio" type="radio"/>
                                    <label for="radio1">
                                        <Image
                                            src={
                                                window.location.origin +
                                                "/img/sell/icons/bnb.svg"
                                            }
                                            className="sell-radio-icon"
                                        />
                                        <span>BNB</span>
                                    </label>
                                </div>
                                <div className="inputGroup">
                                    <input id="radio2" name="radio" type="radio"/>
                                    <label for="radio2">
                                        <Image
                                            src={
                                                window.location.origin +
                                                "/img/sell/icons/eth.svg"
                                            }
                                            className="sell-radio-icon"
                                        />
                                        <span>ETH</span>
                                    </label>
                                </div>
                                <div className="inputGroup">
                                    <input id="radio3" name="radio" type="radio"/>
                                    <label for="radio3">
                                        <Image
                                            src={
                                                window.location.origin +
                                                "/img/sell/icons/busdx.svg"
                                            }
                                            className="sell-radio-icon"
                                        />
                                        <span>BUSDX</span>
                                    </label>
                                </div>
                            </div>
                            <div className="sell-radio-right-sec">
                                <div className="inputGroup">
                                    <input id="radio4" name="radio" type="radio"/>
                                    <label for="radio4" onClick={() => props.setStep(7)}>
                                        <Image
                                            src={
                                                window.location.origin +
                                                "/img/sell/icons/add-bank.svg"
                                            }
                                            className="sell-radio-icon"
                                        />
                                        <span>BANK</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="you-will-be-getting-sec">
                            <p>You Will Be Getting</p>
                            <div className="you-will-be-getting-card">
                                <span>0.256 BNB</span>
                                <Image
                                    src={
                                        window.location.origin +
                                        "/img/sell/bnb-icon-black.png"
                                    }
                                    className="you-will-be-getting-icon"
                                />
                            </div>
                        </div>
                        <div className="sell-value-denomiantion">
                            <h4>1 PSCN 
                                <span>
                                    <Image
                                        src={
                                            window.location.origin +
                                            "/img/coin.png"
                                        }
                                        className="denomination-icon"
                                    />
                                </span>
                            </h4>
                            <span>
                                =
                            </span>
                            <h4>0.1 BNB
                                <span>
                                    <Image
                                        src={
                                            window.location.origin +
                                            "/img/sell/bnb-white.png"
                                        }
                                        className="denomination-icon"
                                    />
                                </span>
                            </h4>
                        </div>
                        <div className="next-btn">
                            <Button className="default-btn" onClick={() => props.setStep(8)}>
                                Sell Now
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default SellIndex;
