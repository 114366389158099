import React from "react";
import { Image, Form, InputGroup, Button, Row, Container, Col } from "react-bootstrap";

const AboutUsIndex = (props) => {
  return (
    <>
      <div className="about-us-sec sm-padding">
        <Container>
            <Row className="justify-content-md-center">
                <Col md={8} lg={9} className="text-center">
                    <div className="section-title">
                        <h2>
                            <Image
                                src={window.location.origin + "/img/coin-img.png"}
                                className="section-coin-icon"
                            />
                            <div className="section-title-info">
                                About 
                                <span>Us</span>
                            </div>
                            <Image
                                src={window.location.origin + "/img/coin-img.png"}
                                className="section-coin-icon"
                            />
                        </h2>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col md={6}>
                    <div className="about-us-info">
                        <p>PoshCoin is a native cryptocurrency token that is also a well-known and dependable e-commerce trading platform that helps both new and experienced traders find profitable opportunities in the turbulent cryptocurrency market. The PoshCoin cryptocurrency and the indigenous marketplace it comes with provide the users with the best and safest options possible to engage in the trade of cannabis.</p>
                        <p>PoshCoin has enabled all of this to be accessible on a single platform. A system that is extremely fast, with many trading tools, is complex yet easy to use, efficient using time-saving alternatives, and has a quick adoption rate for crypto enthusiasts to shift to this modern cannabis-based cryptocurrency effortlessly. The platform has everything you need.</p>
                        <p>In addition, PoshCoin serves to be a safe pathway for users to buy and trade cannabis and cannabis-related products in a legally approved manner. Users do not have to worry about the legal implications of buying cannabis anymore because PoshCoin enables them to procure the plant legally for both medical and recreational purposes.</p>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="about-us-img-sec">
                        <Image
                            src={window.location.origin + "/img/referferfv-1.png"}
                            className="about-us-img"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  );
};

export default AboutUsIndex;
