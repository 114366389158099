import React from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import "../Exchange.css";

const SellScanIndex = (props) => {
  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-back-btn-sec" onClick={() => props.setStep(6)}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="buy-title-sec">
            <span>SELL</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
        </div>
        <div className="buy-body-sec">
          <h3>
            Publishing and graphic design, Lorem ipsum is a placeholder text{" "}
          </h3>
          <Form className="buy-form card-form wallet-form">
            <InputGroup className="mb-3">
              <Form.Control placeholder="65541584981651465gfdfg65Re5" />
              <InputGroup.Text id="basic-addon2">
                <Button>
                  <Image src={window.location.origin + "/img/copy.svg"} />
                </Button>
              </InputGroup.Text>
            </InputGroup>
            <div className="wallet-scan mb-3">
              <Image src={window.location.origin + "/img/scan.png"}  className="qr-scan-img"/>
              <div className="scan-details meta-btn">
                <h5>Scan QR Code For Payment or Connect Metamask </h5>
                <Button>
                  <span>
                    <Image src={window.location.origin + "/img/metamask.png"} />
                  </span>
                  Connect Metamask
                </Button>
              </div>
            </div>
            <Form.Group className="mb-3 transaction-form-control">
              <Form.Control
                type="text"
                placeholder="Paste Your Transaction ID  "
              />
            </Form.Group>
            <div className="next-btn">
              <Button className="default-btn" onClick={() => props.setStep(9)}>
                Next
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SellScanIndex;
