import React from 'react';
import { useConnect } from 'wagmi';
import { Button, Modal, Row, Image } from 'react-bootstrap';

const WalletConnectorModal = (props) => {

  const connectFun = {
    onSuccess(data) {
      console.log('Connect', data);
      props.hideConnectorModal();
    },
  };

  const { connect, connectors, error, isLoading, pendingConnector } = useConnect(
    props.chainId ? { ...connectFun, chainId: props.chainId, } : connectFun
  );

  return (
    <Modal
      className="modal-dialog-center connect-wallet-modal"
      size="md"
      centered
      show={props.show}
      onHide={props.hideConnectorModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Connect Wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
        <div className="wallet-content-box">
          {connectors.map((connector) => (
            <div className="wallet-content mb-3" key={connector.id}>
              {console.log(connector)}
              <Button
                className="wallet-btn"
                disabled={!connector.ready}
                onClick={() => connect({ connector })}>
                <div className="wallet-metamask-card">
                  <div className="wallet-metamask-left-sec">
                    <div className="wallet-metamask-img-sec">
                      <Image
                        src={window.location.origin + `/img/connect-wallet/${connector.id}.svg`}
                        className="section-coin-icon"
                      />
                    </div>
                    <div className="wallet-metamask-info-sec">
                      <h4>
                        {connector.name}
                      </h4>
                    </div>
                  </div>
                  <div className="wallet-metamask-right-sec">
                    {/* <p>Popular</p> */}
                    <p>
                      {!connector.ready && ' (unsupported)'}
                      {isLoading &&
                        connector.id === pendingConnector?.id &&
                        ' connecting'}
                    </p>
                  </div>
                </div>
              </Button>
            </div>
          ))}
        </div>
      </Modal.Body>
      {/* <Modal.Body>
        <Row>
          {connectors.map((connector) => (
            <Button
              className="mb-3"
              disabled={!connector.ready}
              key={connector.id}
              onClick={() => connect({ connector })}
            >
              {connector.name}
              {!connector.ready && ' (unsupported)'}
              {isLoading &&
                connector.id === pendingConnector?.id &&
                ' (connecting)'}
            </Button>
          ))}
        </Row>
      </Modal.Body> */}
    </Modal>
  );

}

export default WalletConnectorModal;