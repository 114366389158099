import { all, fork } from 'redux-saga/effects';
import TransactionSaga from './TransactionSaga';
import HomePageSaga from './HomePageSaga'
import WalletConnectorSaga from './WalletConnectorSaga';
import UserSaga from './UserSaga';

export default function* rootSaga() {
    yield all([
        fork(TransactionSaga),
        fork(HomePageSaga),
        fork(WalletConnectorSaga),
        fork(UserSaga),
    ]);
}