import React, { useState, useEffect } from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import configuration from "react-global-configuration";

const SellSuccessIndex = (props) => {
  const constants = configuration.get("configData");
  const [show, setShow] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (Object.keys(props.sellTokens.data).length > 0) {
      if (props.sellTokens.data.admin_payment_status == 1) {
        setIsCompleted(true);
      }
      setShow(true);
    } else {
      props.resetData();
    }
  }, []);;

  const copy = (message) => {
    props.dispatch(getSuccessNotificationMessage(message))
  }

  return (
    <>
      {show ?
        <div className="buy-sec">
          <div className="buy-header-sec">
            <div className="buy-back-btn-sec" onClick={() => props.resetData()}>
              <Image
                src={window.location.origin + "/img/buy/back-arrow.svg"}
                className="back-arrow-icon"
              />
              <span>Back</span>
            </div>
            <div className="buy-title-sec">
              <span>SELL</span>
              <Image
                src={window.location.origin + "/img/buy/buy-icon.svg"}
                className="buy-icon"
              />
            </div>
          </div>
          <div className="buy-body-sec">
            <div className="buy-success-img-sec">
              <Image
                src={isCompleted ?
                  window.location.origin + "/img/success.gif"
                  : window.location.origin + "/img/pending.gif"
                }
                className="buy-success-img"
              />
              {!isCompleted ?
                <h5>
                  Payment Recieved Tokens will be added to your account in 2 Hours
                </h5>
                : null
              }
              <div className="you-will-be-getting-sec">
                <p>Reference ID -</p>
                <div className="you-will-be-getting-card">
                  <span>{props.sellTokens.data.payment_id}</span>
                  <CopyToClipboard text={props.sellTokens.data.payment_id}
                    onCopy={() => copy("Transacton id copied")}
                  >
                    <Button>
                      <Image src={window.location.origin + "/img/copy.svg"} />
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
              {props.sellTokens.data.user_transaction_hash ?
                <div className="you-will-be-getting-sec">
                  <p>PSCN Hash -</p>
                  <div>
                    <div className="you-will-be-getting-card">
                      <span>{
                        `${props.sellTokens.data.user_transaction_hash.substring(0, 4)}
                  ...
                  ${props.sellTokens.data.user_transaction_hash.substring(
                          props.sellTokens.data.user_transaction_hash.length - 4,
                          props.sellTokens.data.user_transaction_hash.length
                        )}
                  `}</span>
                      <CopyToClipboard text={props.sellTokens.data.user_transaction_hash}
                        onCopy={() => copy("PSCN hash copied")}
                      >
                        <Button>
                          <Image src={window.location.origin + "/img/copy.svg"} />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    <a href={`${constants.binance_block_explorer_urls}tx/${props.sellTokens.data.user_transaction_hash}`}
                      target="_blank"
                    >View on explorer</a>
                  </div>
                </div>
                : null
              }
              {props.sellTokens.data.admin_transaction_hash ?
                <div className="you-will-be-getting-sec">
                  <p>{props.sellData.paymentMethod.symbol} Hash -</p>
                  <div>
                    <div className="you-will-be-getting-card">
                      <span>{
                        `${props.sellTokens.data.admin_transaction_hash.substring(0, 4)}
                  ...
                  ${props.sellTokens.data.admin_transaction_hash.substring(
                          props.sellTokens.data.admin_transaction_hash.length - 4,
                          props.sellTokens.data.admin_transaction_hash.length
                        )}
                  `}</span>
                      <CopyToClipboard text={props.sellTokens.data.admin_transaction_hash}
                        onCopy={() => copy(`${props.sellData.paymentMethod.symbol} hash copied`)}
                      >
                        <Button>
                          <Image src={window.location.origin + "/img/copy.svg"} />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    <a
                      href={`${props.sellData.paymentMethod.blockExplorerUrl}tx/${props.sellTokens.data.admin_transaction_hash}`}
                      target="_blank"
                    >View on explorer</a>
                  </div>
                </div>
                : null
              }
            </div>
            <div className="next-btn">
              <Button className="default-btn" onClick={() => props.resetData()}>
                Home
              </Button>
            </div>
          </div>
        </div>
        : null
      }
    </>
  );
};


const mapStateToProps = (state) => ({
  sellTokens: state.transaction.sellTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(SellSuccessIndex);
