import React, { useEffect, useState } from "react";
import {
  Image,
  Form,
  InputGroup,
  Button,
  Row,
  Container,
  FormControl,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userRegisterStart } from "../store/actions/UserAction";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";

const RegisterIndex = (props) => {
  const navigate = useNavigate();
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  useEffect(() => {
    if (
      !skipRender &&
      !props.register.loading &&
      Object.keys(props.register.data).length > 0
    ) {
      if (props.register.data.is_email_verified === 0) {
        navigate("/register-verification");
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [props.register]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    email: Yup.string().email(t("invalid_email")).required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, "Whitespaces not allowed")
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, "Password must be at least 6 characters"),
    password_confirmation: Yup.string()
      .required(t("required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Passwords do not match"
        ),
      }),
  });

  const register = (values) => {
    props.dispatch(userRegisterStart(values));
  };

  return (
    <>
      <div className="login-sec">
        <div className="login-box">
          <Container>
            <Row className="align-items-center">
              <Col md={12} xl={5} lg={5} className="resp-margin-btm-md">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => register(values)}
                >
                  {({ errors, touched }) => (
                    <FORM className="auth-form-sec">
                      <div className="auth-form-box">
                        <div className="auth-logo-sec">
                          <Link to="/">
                            <Image
                              className="logo"
                              src="../img/logo-exchange.png"
                            />
                          </Link>
                        </div>
                        <h2>Join Us</h2>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Full Name <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder={t("name_placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="text-danger"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Email <span>*</span>
                          </Form.Label>
                          <Field
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder={t("email_placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Password <span>*</span>
                          </Form.Label>
                          <InputGroup className="mb-0">
                            <Field
                              type={loginPasswordVisible ? "text" : "password"}
                              name="password"
                              placeholder={t("password_placeholder")}
                              className="form-control"
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              onClick={() =>
                                setLoginPasswordVisible(!loginPasswordVisible)
                              }
                            >
                              {loginPasswordVisible ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <g clipPath="url(#clip0_1_56)">
                                    <path
                                      fill="#3C3C41"
                                      d="M12 6.5c2.76 0 5 2.24 5 5 0 .51-.1 1-.24 1.46l3.06 3.06c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l2.17 2.17c.47-.14.96-.24 1.47-.24zM2.71 3.16a.996.996 0 000 1.41l1.97 1.97A11.892 11.892 0 001 11.5C2.73 15.89 7 19 12 19c1.52 0 2.97-.3 4.31-.82l2.72 2.72a.996.996 0 101.41-1.41L4.13 3.16c-.39-.39-1.03-.39-1.42 0zM12 16.5c-2.76 0-5-2.24-5-5 0-.77.18-1.5.49-2.14l1.57 1.57c-.03.18-.06.37-.06.57 0 1.66 1.34 3 3 3 .2 0 .38-.03.57-.07L14.14 16c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 00-2.64-2.64l2.64 2.64z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1_56">
                                      <path
                                        fill="#fff"
                                        d="M0 0H24V24H0z"
                                      ></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              ) : (
                                <svg
                                  width="19"
                                  height="16"
                                  viewBox="0 0 19 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.5 1.5C5.86985 1.5 2.57784 3.77968 0.148665 7.4825C-0.0495551 7.78586 -0.0495551 8.20968 0.148665 8.51304C2.57784 12.2203 5.86985 14.5 9.5 14.5C13.1302 14.5 16.4222 12.2203 18.8513 8.5175C19.0496 8.21414 19.0496 7.79032 18.8513 7.48696C16.4222 3.77968 13.1302 1.5 9.5 1.5ZM9.76041 12.5772C7.35067 12.7512 5.36069 10.4715 5.51227 7.7011C5.63665 5.41695 7.24962 3.56555 9.23959 3.42279C11.6493 3.2488 13.6393 5.52848 13.4877 8.2989C13.3595 10.5786 11.7465 12.43 9.76041 12.5772ZM9.63992 10.4626C8.34177 10.5563 7.26905 9.32944 7.35456 7.8394C7.42063 6.6081 8.29124 5.61325 9.36397 5.53294C10.6621 5.43926 11.7348 6.6661 11.6493 8.15614C11.5794 9.3919 10.7088 10.3868 9.63992 10.4626Z"
                                    fill="black"
                                  />
                                </svg>
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Confirm Password <span>*</span>
                          </Form.Label>
                          <InputGroup className="mb-0">
                            <Field
                              type={
                                confirmPasswordVisible ? "text" : "password"
                              }
                              name="password_confirmation"
                              placeholder={t("password_placeholder")}
                              className="form-control"
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              onClick={() =>
                                setConfirmPasswordVisible(
                                  !confirmPasswordVisible
                                )
                              }
                            >
                              {confirmPasswordVisible ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <g clipPath="url(#clip0_1_56)">
                                    <path
                                      fill="#3C3C41"
                                      d="M12 6.5c2.76 0 5 2.24 5 5 0 .51-.1 1-.24 1.46l3.06 3.06c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l2.17 2.17c.47-.14.96-.24 1.47-.24zM2.71 3.16a.996.996 0 000 1.41l1.97 1.97A11.892 11.892 0 001 11.5C2.73 15.89 7 19 12 19c1.52 0 2.97-.3 4.31-.82l2.72 2.72a.996.996 0 101.41-1.41L4.13 3.16c-.39-.39-1.03-.39-1.42 0zM12 16.5c-2.76 0-5-2.24-5-5 0-.77.18-1.5.49-2.14l1.57 1.57c-.03.18-.06.37-.06.57 0 1.66 1.34 3 3 3 .2 0 .38-.03.57-.07L14.14 16c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 00-2.64-2.64l2.64 2.64z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1_56">
                                      <path
                                        fill="#fff"
                                        d="M0 0H24V24H0z"
                                      ></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              ) : (
                                <svg
                                  width="19"
                                  height="16"
                                  viewBox="0 0 19 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.5 1.5C5.86985 1.5 2.57784 3.77968 0.148665 7.4825C-0.0495551 7.78586 -0.0495551 8.20968 0.148665 8.51304C2.57784 12.2203 5.86985 14.5 9.5 14.5C13.1302 14.5 16.4222 12.2203 18.8513 8.5175C19.0496 8.21414 19.0496 7.79032 18.8513 7.48696C16.4222 3.77968 13.1302 1.5 9.5 1.5ZM9.76041 12.5772C7.35067 12.7512 5.36069 10.4715 5.51227 7.7011C5.63665 5.41695 7.24962 3.56555 9.23959 3.42279C11.6493 3.2488 13.6393 5.52848 13.4877 8.2989C13.3595 10.5786 11.7465 12.43 9.76041 12.5772ZM9.63992 10.4626C8.34177 10.5563 7.26905 9.32944 7.35456 7.8394C7.42063 6.6081 8.29124 5.61325 9.36397 5.53294C10.6621 5.43926 11.7348 6.6661 11.6493 8.15614C11.5794 9.3919 10.7088 10.3868 9.63992 10.4626Z"
                                    fill="black"
                                  />
                                </svg>
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password_confirmation"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <div className="auth-btn-sec mt-4">
                          <Button
                            className="default-btn"
                            type="submit"
                            disabled={props.register.buttonDisable}
                          >
                            {props.register.loadingButtonContent
                              ? props.register.loadingButtonContent
                              : "Register"}
                          </Button>
                        </div>
                        <div className="auth-footer-link">
                          <span>Already have an account?</span>
                          <Link to="/login">Login</Link>
                        </div>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </Col>
              <Col md={12} xl={7} lg={7}>
                <div className="auth-right-img-sec">
                  <Image
                    className="auth-right-img"
                    src="../img/auth/exchange-auth-right-img.png"
                  />
                  <div className="auth-right-vertical-center-content">
                    <h3>Become a seller & buyer</h3>
                    <div className="auth-right-info-box">
                      <div className="auth-right-info-card">
                        <Image
                          className="auth-right-info-icon"
                          src="../img/auth/poshcoin.png"
                        />
                        <h4>Enter poshCoin</h4>
                      </div>
                      <div className="auth-right-info-card">
                        <Image
                          className="auth-right-info-icon"
                          src="../img/auth/payment.png"
                        />
                        <h4>Receive Payment</h4>
                      </div>
                      <div className="auth-right-info-card">
                        <Image
                          className="auth-right-info-icon"
                          src="../img/auth/wallet.png"
                        />
                        <h4>Wallet Address</h4>
                      </div>
                      <div className="auth-right-info-card">
                        <Image
                          className="auth-right-info-icon"
                          src="../img/auth/bitcoin.png"
                        />
                        <h4>Receive Crypto</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  register: state.users.register,
  usernameValidation: state.users.usernameValidation,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(RegisterIndex));
