import React, { useState } from "react";
import "../Exchange.css";
import BankDetails from "./BankDetails";
import PaymentSelector from "./PaymentSelector";
import SellSection from "./SellSection";
import ReceiverWalletIndex from "./ReceiverWalletIndex";
import SellSuccessIndex from "./SellSuccessIndex";
import { WagmiConfig, createClient, chain, configureChains } from 'wagmi';
import { Buffer } from "buffer";
// import { ConnectKitProvider, getDefaultClient } from "connectkit";
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import SellPaymentWaitSec from "./SellPaymentWaitSec";
import SellPending from "./SellPending";
import SellFailure from "./SellFailure";
import configuration from "react-global-configuration";

const alchemyId = process.env.ALCHEMY_ID;

const SellIndex = (props) => {
  const constants = configuration.get("configData");
  const initialSellData = {
    paymentMethod: constants.chains[0],
    bankDetails: {
      account_number: "",
      route_number: "",
      first_name: "",
      last_name: "",
      bank_type: "savings",
      business_name: "",
    },
    transactionId: "",
    toWalletAddress: "",
    sellTokens: 100,
    exchangeValue: "",
  };
  const [step, setStep] = useState(1);
  const [sellData, setSellData] = useState(initialSellData);


  const resetData = () => {
    setSellData(initialSellData);
    setStep(1);
    props.setSection("buy");
  }

  const createWalletClient = () => {
    const binanceChain = {
      id: Number(constants.binance_network_id),
      name: constants.binance_chain_name,
      nativeCurrency: {
        decimals: Number(constants.binance_native_currency_decimals),
        name: constants.binance_chain_name,
        symbol: constants.binance_native_currency_symbol,
      },
      rpcUrls: {
        default: constants.binance_rpc_url,
      },
      blockExplorers: {
        default: { name: constants.binance_chain_name, url: constants.binance_block_explorer_urls },
      },
      testnet: true,
    };

    const defaultChains = [binanceChain];
    if (!window.Buffer) window.Buffer = Buffer;
    // const client = createClient({
    //   ...getDefaultClient({
    //     appName: "Your App Name",
    //     alchemyId,
    //     chains,
    //   }),
    // });

    const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
      alchemyProvider({ apiKey: 'yourAlchemyApiKey' }),
      publicProvider(),
    ])

    const client = createClient({
      autoConnect: true,
      connectors: [
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
          chains,
          options: {
            appName: 'wagmi',
          },
        }),
        new WalletConnectConnector({
          chains,
          options: {
            qrcode: true,
          },
        }),
      ],
      provider,
      webSocketProvider,
    })


    return client;
  }


  return (
    <WagmiConfig client={createWalletClient()}>
      <>
        {step === 1 ?
          <PaymentSelector
            setSection={props.setSection}
            setStep={setStep}
            sellData={sellData}
            setSellData={setSellData}
          />
          : step === 2 && sellData.paymentMethod === "USD" ?
            <BankDetails
              setStep={setStep}
              sellData={sellData}
              setSellData={setSellData}
            />
            : step === 2 ?
              <ReceiverWalletIndex
                setStep={setStep}
                sellData={sellData}
                setSellData={setSellData}
              />
              : step === 3 ?
                <SellSection
                  setStep={setStep}
                  sellData={sellData}
                  setSellData={setSellData}
                />
                : step === 4 ?
                  <SellPaymentWaitSec
                    setStep={setStep}
                  />
                  : step === 5 ?
                    <SellSuccessIndex
                      sellData={sellData}
                      resetData={resetData} />
                    : step === 6 ?
                      <SellPending
                        sellData={sellData}
                        resetData={resetData} />
                      : step === 7 ?
                        <SellFailure
                          resetData={resetData} />
                        : null

        }
      </>
    </WagmiConfig>
  )
};

export default SellIndex;
