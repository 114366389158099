import React, { useState, useEffect } from "react";
import { Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AboutUsIndex from "./AboutUsIndex";
import BuyCard from "./BuyCard";
import BuyScan from "./BuyScan";
import BuySuccess from "./BuySuccess";
import BuyWallet from "./BuyWallet";
import FAQIndex from "./FAQIndex";
import HowItsWorkIndex from "./HowItsWorkIndex";
import LandingTabIndex from "./LandingTabIndex";
import AddBankIndex from "./Sell/AddBankIndex";
import ReceiverWalletIndex from "./Sell/ReceiverWalletIndex";
import SellIndex from "./Sell/SellIndex";
import SellScanIndex from "./Sell/SellScanIndex";
import SellSuccessIndex from "./Sell/SellSuccessIndex";

const LandingPageIndexOld = (props) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    setStep(1);
  }, []);

  return (
    <>
      <div className="exchange-sec">
        <Container>
          <Row className="align-items-center">
            <Col md={12} xl={6} lg={6}>
              <div className="banner-left-sec">
                <div className="banner-content">
                  <h1>
                    Crypto Exchange <br></br>....<span>Platform</span>{" "}
                  </h1>
                  <p>
                    Publishing and graphic design, Lorem ipsum is a placeholder
                    text commonly used to demonstrate the visual form of a
                    document or a typeface without relying on meaningful
                    content.
                  </p>
                  <div className="banner-img-sec">
                    <Image
                      src={window.location.origin + "/img/banner-img.png"}
                      className="banner-img"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} xl={6} lg={6}>
              {step == 1 && (
                <div className="step-1">
                  <LandingTabIndex setStep={setStep} />
                </div>
              )}

              {step == 2 && (
                <div className="step-2">
                  <BuyWallet setStep={setStep} />
                </div>
              )}
              {step == 3 && (
                <div className="step-3">
                  <BuyCard setStep={setStep} />
                </div>
              )}
              {step == 4 && (
                <div className="step-4">
                  <BuyScan setStep={setStep} />
                </div>
              )}
              {step == 5 && (
                <div className="step-5">
                  <BuySuccess setStep={setStep} />
                </div>
              )}
              {step == 6 && (
                <div className="step-6">
                  <SellIndex setStep={setStep} />
                </div>
              )}
              {step == 7 && (
                <div className="step-7">
                  <AddBankIndex setStep={setStep} />
                </div>
              )}
              {step == 8 && (
                <div className="step-8">
                  <SellScanIndex setStep={setStep} />
                </div>
              )}
              {step == 9 && (
                <div className="step-9">
                  <ReceiverWalletIndex setStep={setStep} />
                </div>
              )}
              {step == 10 && (
                <div className="step-9">
                  <SellSuccessIndex setStep={setStep} />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <AboutUsIndex />
      <HowItsWorkIndex />
      <FAQIndex />
    </>
  );
};

export default LandingPageIndexOld;
