// Buy Tokens
export const BUY_TOKENS_START = "BUY_TOKENS_START";
export const BUY_TOKENS_SUCCESS = "BUY_TOKENS_SUCCESS";
export const BUY_TOKENS_FAILURE = "BUY_TOKENS_FAILURE";

// Sell Tokens
export const SELL_TOKENS_START = "SELL_TOKENS_START";
export const SELL_TOKENS_SUCCESS = "SELL_TOKENS_SUCCESS";
export const SELL_TOKENS_FAILURE = "SELL_TOKENS_FAILURE";

// Wallet Transaction List
export const FETCH_WALLET_TRANSACTION_LIST_START = "FETCH_WALLET_TRANSACTION_LIST_START";
export const FETCH_WALLET_TRANSACTION_LIST_SUCCESS = "FETCH_WALLET_TRANSACTION_LIST_SUCCESS";
export const FETCH_WALLET_TRANSACTION_LIST_FAILURE = "FETCH_WALLET_TRANSACTION_LIST_FAILURE";
export const FETCH_MORE_WALLET_TRANSACTION_LIST_START = "FETCH_MORE_WALLET_TRANSACTION_LIST_START";


// Wallet Transaction View
export const FETCH_WALLET_TRANSACTION_DETAILS_START = "FETCH_WALLET_TRANSACTION_DETAILS_START";
export const FETCH_WALLET_TRANSACTION_DETAILS_SUCCESS = "FETCH_WALLET_TRANSACTION_DETAILS_SUCCESS";
export const FETCH_WALLET_TRANSACTION_DETAILS_FAILURE = "FETCH_WALLET_TRANSACTION_DETAILS_FAILURE";

//Wallet Connector
export const SET_WALLET_DETAILS = "SET_WALLET_DETAILS"; 


// FAQ Lists

 export const FETCH_FAQS_LIST_START="FETCH_FAQS_LIST_START";
 export const FETCH_FAQS_LIST_SUCCESS="FETCH_FAQS_LIST_SUCCESS";
 export const FETCH_FAQS_LIST_FAILURE="FETCH_FAQS_LIST_FAILURE";

export const AMOUNT_CONVERSION_START = "AMOUNT_CONVERSION_START";
export const AMOUNT_CONVERSION_SUCCESS = "AMOUNT_CONVERSION_SUCCESS";
export const AMOUNT_CONVERSION_FAILURE = "AMOUNT_CONVERSION_FAILURE";

//Stripe Payment
export const STRIPE_PAYMENT_START = "STRIPE_PAYMENT_START";
export const STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS";
export const STRIPE_PAYMENT_FAILURE = "STRIPE_PAYMENT_FAILURE";

//User Authentications
export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const REGENERATE_EMAIL_VERIFICATION_CODE_START = "REGENERATE_EMAIL_VERIFICATION_CODE_START";
export const REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS = "REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS";
export const REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE = "REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE";

export const VERIFY_EMAIL_START = "VERIFY_EMAIL_START";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const TWO_STEP_AUTH_LOGIN_START = "TWO_STEP_AUTH_LOGIN_START";
export const TWO_STEP_AUTH_LOGIN_SUCCESS = "TWO_STEP_AUTH_LOGIN_SUCCESS";
export const TWO_STEP_AUTH_LOGIN_FAILURE = "TWO_STEP_AUTH_LOGIN_FAILURE";

export const TWO_STEP_AUTH_RESEND_CODE_START = "TWO_STEP_AUTH_RESEND_CODE_START";
export const TWO_STEP_AUTH_RESEND_CODE_SUCCESS = "TWO_STEP_AUTH_RESEND_CODE_SUCCESS";
export const TWO_STEP_AUTH_RESEND_CODE_FAILURE = "TWO_STEP_AUTH_RESEND_CODE_FAILURE";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const TWO_STEP_AUTH_UPDATE_START = "TWO_STEP_AUTH_UPDATE_START";
export const TWO_STEP_AUTH_UPDATE_SUCCESS = "TWO_STEP_AUTH_UPDATE_SUCCESS";
export const TWO_STEP_AUTH_UPDATE_FAILURE = "TWO_STEP_AUTH_UPDATE_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const KYC_STATUS_UPDATE_START = "KYC_STATUS_UPDATE_START";
export const KYC_STATUS_UPDATE_SUCCESS = "KYC_STATUS_UPDATE_SUCCESS";
export const KYC_STATUS_UPDATE_FAILURE = "KYC_STATUS_UPDATE_FAILURE";

export const KYC_APPLICANT_START = "KYC_APPLICANT_START";
export const KYC_APPLICANT_SUCCESS = "KYC_APPLICANT_SUCCESS";
export const KYC_APPLICANT_FAILURE = "KYC_APPLICANT_FAILURE";