import React from "react";
import { Image, Form, InputGroup, Button, Row, Col } from "react-bootstrap";
import "./Exchange.css";

const BuyCard = (props) => {
  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-back-btn-sec" onClick={() => props.setStep(2)}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="buy-title-sec">
            <span>BUY</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
        </div>
        <div className="buy-body-sec">
          <h3>
            Publishing and graphic design, Lorem ipsum is a placeholder text{" "}
          </h3>
          <Form className="buy-form card-form">
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3 transaction-form-control">
                  <Form.Control
                    type="text"
                    placeholder="2135 - xxxx - xxxx- 1234"
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="card-details mb-3 transaction-form-control">
              <Row className="gy-3">
                <Col md={4}>
                  <Form.Group>
                    <Form.Control type="text" placeholder="MM/YY" />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Control type="text" placeholder="CVV" />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <div className="card-image">
                    <Image
                      src={window.location.origin + "/img/visa.svg"}
                      className="card-icon"
                    />
                    <Image
                      src={window.location.origin + "/img/american-express.svg"}
                      className="card-icon"
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="pay-amount">
              Pay Amount - <span>8965.2$</span>
            </div>

            <div className="next-btn">
              <Button className="default-btn" onClick={() => props.setStep(4)}>
                Buy Tokens
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default BuyCard;
