import {
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REGENERATE_EMAIL_VERIFICATION_CODE_START,
  REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS,
  REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  TWO_STEP_AUTH_LOGIN_START,
  TWO_STEP_AUTH_LOGIN_SUCCESS,
  TWO_STEP_AUTH_LOGIN_FAILURE,
  TWO_STEP_AUTH_RESEND_CODE_START,
  TWO_STEP_AUTH_RESEND_CODE_SUCCESS,
  TWO_STEP_AUTH_RESEND_CODE_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  TWO_STEP_AUTH_UPDATE_START,
  TWO_STEP_AUTH_UPDATE_SUCCESS,
  TWO_STEP_AUTH_UPDATE_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  KYC_STATUS_UPDATE_START,
  KYC_STATUS_UPDATE_SUCCESS,
  KYC_STATUS_UPDATE_FAILURE,
  KYC_APPLICANT_START,
  KYC_APPLICANT_SUCCESS,
  KYC_APPLICANT_FAILURE,
} from "./ActionConstant";

// Get user details actions.

export function fetchUserDetailsStart(data) {
  return {
    type: FETCH_USER_DETAILS_START,
    data,
  };
}

export function fetchUserDetailsSuccess(data) {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    data,
  };
}

export function fetchUserDetailsFailure(error) {
  return {
    type: FETCH_USER_DETAILS_FAILURE,
    error,
  };
}
// Update user detatils actions

export function updateUserDetailsStart(data) {
  return {
    type: UPDATE_USER_DETAILS_START,
    data,
  };
}

export function updateUserDetailsSuccess(data) {
  return {
    type: UPDATE_USER_DETAILS_SUCCESS,
    data,
  };
}

export function updateUserDetailsFailure(error) {
  return {
    type: UPDATE_USER_DETAILS_FAILURE,
    error,
  };
}

// User login actions.

export function userLoginStart(data) {
  return {
    type: LOGIN_START,
    data,
  };
}

export function userLoginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
}

export function userLoginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error,
  };
}

// User register actions.

export function userRegisterStart(data) {
  return {
    type: REGISTER_START,
    data,
  };
}

export function userRegisterSuccess(data) {
  return {
    type: REGISTER_SUCCESS,
    data,
  };
}

export function userRegisterFailure(error) {
  return {
    type: REGISTER_FAILURE,
    error,
  };
}

// User forgot-password actions.

export function forgotPasswordStart(data) {
  return {
    type: FORGOT_PASSWORD_START,
    data,
  };
}

export function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    data,
  };
}

export function forgotPassworFailure(error) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    error,
  };
}

// User logout actions.

export function logoutStart(data) {
  return {
    type: LOGOUT_START,
    data,
  };
}

export function logoutSuccess(data) {
  return {
    type: LOGOUT_SUCCESS,
    data,
  };
}

export function logoutFailure(error) {
  return {
    type: LOGOUT_FAILURE,
    error,
  };
}

//regenerate email verification code

export function regenerateEmailVerificationCodeStart(data) {
  return {
    type: REGENERATE_EMAIL_VERIFICATION_CODE_START,
    data,
  };
}

export function regenerateEmailVerificationCodeSuccess(data) {
  return {
    type: REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS,
    data,
  };
}

export function regenerateEmailVerificationCodeFailure(error) {
  return {
    type: REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE,
    error,
  };
}

//User Email Verification
export function verifyEmailStart(data) {
  return {
    type: VERIFY_EMAIL_START,
    data,
  };
}

export function verifyEmailSuccess(data) {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    data,
  };
}

export function verifyEmailFailure(error) {
  return {
    type: VERIFY_EMAIL_FAILURE,
    error,
  };
}

//Two step Auth Login
export function twoStepAuthLoginStart(data) {
  return {
    type: TWO_STEP_AUTH_LOGIN_START,
    data,
  };
}

export function twoStepAuthLoginSuccess(data) {
  return {
    type: TWO_STEP_AUTH_LOGIN_SUCCESS,
    data,
  };
}

export function twoStepAuthLoginFailure(error) {
  return {
    type: TWO_STEP_AUTH_LOGIN_FAILURE,
    error,
  };
}

// Two step Auth resend Code
export function twoStepAuthResendCodeStart(data) {
  return {
    type: TWO_STEP_AUTH_RESEND_CODE_START,
    data,
  };
}

export function twoStepAuthResendCodeSuccess(data) {
  return {
    type: TWO_STEP_AUTH_RESEND_CODE_SUCCESS,
    data,
  };
}

export function twoStepAuthResendCodeFailure(error) {
  return {
    type: TWO_STEP_AUTH_RESEND_CODE_FAILURE,
    error,
  };
}

//Change Password
export function changePasswordStart(data) {
  return {
    type: CHANGE_PASSWORD_START,
    data,
  };
}

export function changePasswordSuccess(data) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    data,
  };
}

export function changePasswordFailure(error) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    error,
  };
}

// Delete User Account
export function deleteAccountStart(data) {
  return {
    type: DELETE_ACCOUNT_START,
    data,
  };
}

export function deleteAccountSuccess(data) {
  return {
    type: DELETE_ACCOUNT_SUCCESS,
    data,
  };
}

export function deleteAccountFailure(error) {
  return {
    type: DELETE_ACCOUNT_FAILURE,
    error,
  };
}


//Two Step Auth Update
export function twoStepAuthUpdateStart(data) {
  return {
    type: TWO_STEP_AUTH_UPDATE_START,
    data,
  };
}

export function twoStepAuthUpdateSuccess(data) {
  return {
    type: TWO_STEP_AUTH_UPDATE_SUCCESS,
    data,
  };
}

export function twoStepAuthUpdateFailure(error) {
  return {
    type: TWO_STEP_AUTH_UPDATE_FAILURE,
    error,
  };
}

// User Password reset
export function resetPasswordStart(data) {
  return {
    type: RESET_PASSWORD_START,
    data,
  };
}

export function resetPasswordSuccess(data) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    data,
  };
}

export function resetPasswordFailure(error) {
  return {
    type: RESET_PASSWORD_FAILURE,
    error,
  };
}

// User KYC
export function kycStatusUpdateStart(data) {
  return {
    type: KYC_STATUS_UPDATE_START,
    data,
  };
}

export function kycStatusUpdateSuccess(data) {
  return {
    type: KYC_STATUS_UPDATE_SUCCESS,
    data,
  };
}

export function kycStatusUpdateFailure(error) {
  return {
    type: KYC_STATUS_UPDATE_FAILURE,
    error,
  };
}

export function kycApplicantStart(data) {
  return {
    type: KYC_APPLICANT_START,
    data,
  };
}

export function kycApplicantSuccess(data) {
  return {
    type: KYC_APPLICANT_SUCCESS,
    data,
  };
}

export function kycApplicantFailure(error) {
  return {
    type: KYC_APPLICANT_FAILURE,
    error,
  };
}