import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";

import { getSuccessNotificationMessage, getErrorNotificationMessage } from "../../helper/ToastNotification";

import { FETCH_FAQS_LIST_START } from "../actions/ActionConstant";

import { fetchFaqsListSuccess, fetchFaqsListFailure } from "../actions/HomePageAction";


function* fetchFaqsListAPI(action) {
    try {
        const response = yield api.postMethod("faqs_list",action.data);

        if (response.data.success) {
            yield put(fetchFaqsListSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(fetchFaqsListFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchFaqsListFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

export default function* pageSaga() {
    yield all([
        yield takeLatest(FETCH_FAQS_LIST_START, fetchFaqsListAPI),

    ]);
}
