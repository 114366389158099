import React, { useState } from "react";
import "../Exchange.css";
import { WagmiConfig, createClient, chain, configureChains, useAccount } from 'wagmi';
import { Buffer } from "buffer";
// import { ConnectKitProvider, getDefaultClient } from "connectkit";
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import ExchangeRateSec from "./ExchangeRateSec";
import WalletConnectSec from "./WalletConnectSec";
import CardPayment from "./CardPayment";
import ScanPayment from "./ScanPayment";
import BuySuccess from "./BuySuccess";
import BuyPaymentWaitSec from "./BuyPaymentWaitSec";
import BuyPending from "./BuyPending";
import BuyFailure from "./BuyFailure";
import configuration from "react-global-configuration";

const alchemyId = process.env.ALCHEMY_ID;

const BuyIndex = (props) => {
  const constants = configuration.get("configData");

  const createWalletClient = () => {

    const defaultChains = constants.chains.filter(chain => chain.isNetwork).map((chain, i) => {
      return {
        id: chain.id,
        name: chain.name,
        nativeCurrency: {
          decimals: chain.decimals,
          name: chain.name,
          symbol: chain.symbol,
        },
        rpcUrls: {
          default: chain.rpcUrl,
        },
        blockExplorers: {
          default: { name: chain.name, url: chain.blockExplorerUrl },
        },
        testnet: chain.testnet,
      }
    });

    if (!window.Buffer) window.Buffer = Buffer;
    // const client = createClient({
    //   ...getDefaultClient({
    //     appName: "Your App Name",
    //     alchemyId,
    //     chains,
    //   }),
    // });

    const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
      alchemyProvider({ apiKey: 'yourAlchemyApiKey' }),
      publicProvider(),
    ])

    const client = createClient({
      autoConnect: true,
      connectors: [
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
          chains,
          options: {
            appName: 'wagmi',
          },
        }),
        new WalletConnectConnector({
          chains,
          options: {
            qrcode: true,
          },
        }),
      ],
      provider,
      webSocketProvider,
    })
    return client;
  }

  const defaultData = {
    paymentMethod: constants.chains[0],
    toWalletAddress: "",
    transactionId: "",
    buyTokens: 100,
    exchangeValue: "",
  };

  const [step, setStep] = useState(1);
  const [buyData, setBuyData] = useState(defaultData);

  const reset = () => {
    setStep(1);
    setBuyData(defaultData);
  }

  return (
    <WagmiConfig client={createWalletClient()}>
      <>
        {step === 1 ?
          <ExchangeRateSec
            setSection={props.setSection}
            setStep={setStep}
            buyData={buyData}
            setBuyData={setBuyData}
          />
          : step === 2 ?
            <WalletConnectSec
              setStep={setStep}
              buyData={buyData}
              setBuyData={setBuyData}
            />
            : step === 3 && buyData.paymentMethod.value === "usd" ?
              <CardPayment
                setStep={setStep}
                buyData={buyData}
                setBuyData={setBuyData}
              />
              : step === 3 ?
                <ScanPayment
                  setStep={setStep}
                  buyData={buyData}
                  setBuyData={setBuyData}
                />
                : step === 4 ?
                  <BuyPaymentWaitSec
                    setStep={setStep}
                  />
                  : step === 5 ?
                    <BuySuccess
                      buyData={buyData}
                      reset={reset} />
                    : step === 6 ?
                      <BuyPending
                        buyData={buyData}
                        reset={reset} />
                      : step === 7 ?
                        <BuyFailure reset={reset} />
                        : null
        }
      </>
    </WagmiConfig>
  );

};

export default BuyIndex;
