import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import EmptyFooter from "./Footer/EmptyFooter";
import EmptyHeader from "./Header/EmptyHeader";

const EmptyLayout = (props) => {

  return (
    <>
    <div className="empty-layout-sec">
        <EmptyHeader/>
        <ToastContainer />
        {props.children}
        <EmptyFooter />
      </div>
    </>
  );
};

export default EmptyLayout;
