import {
  AMOUNT_CONVERSION_FAILURE,
  AMOUNT_CONVERSION_START,
  AMOUNT_CONVERSION_SUCCESS,
  SET_WALLET_DETAILS
} from "../actions/ActionConstant"

const initialState = {
  connectedWallet: null,
  amountConversion: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
}

const WalletConnectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLET_DETAILS:
      return {
        ...state,
        connectedWallet: action.data,
      };
    case AMOUNT_CONVERSION_START:
      return {
        ...state,
        amountConversion: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case AMOUNT_CONVERSION_SUCCESS:
      return {
        ...state,
        amountConversion: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case AMOUNT_CONVERSION_FAILURE:
      return {
        ...state,
        amountConversion: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: true,
          loadingButtonContent: null,
        }
      }
    default:
      return state;
  }
}

export default WalletConnectorReducer;