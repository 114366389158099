import React from 'react';
import { Image, Button } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getSuccessNotificationMessage } from '../../helper/ToastNotification';

const BuyPending = (props) => {

  const copy = (message) => {
    props.dispatch(getSuccessNotificationMessage(message))
  }

  return (
    <div className="buy-sec">
      <div className="buy-header-sec">
        <div className="buy-back-btn-sec" onClick={() => props.reset()}>
          <Image
            src={window.location.origin + "/img/buy/back-arrow.svg"}
            className="back-arrow-icon"
          />
          <span>Back</span>
        </div>
        <div className="buy-title-sec">
          <span>BUY</span>
          <Image
            src={window.location.origin + "/img/buy/buy-icon.svg"}
            className="buy-icon"
          />
        </div>
      </div>
      <div className="buy-body-sec">
        <div className="buy-success-img-sec">
          <Image
            src={window.location.origin + "/img/pending.gif"}
            className="buy-success-img"
          />
          <h5>
            Payment Recieved Tokens will be added to your account in 2 Hours
          </h5>
          {props.buyData.transactionId ?
            <div className="you-will-be-getting-sec">
              <p>{props.buyData.paymentMethod.label} Hash -</p>
              <div>
                <div className="you-will-be-getting-card">
                  <span>{
                    `${props.buyData.transactionId.substring(0, 4)}
                  ...
                  ${props.buyData.transactionId.substring(
                      props.buyData.transactionId.length - 4,
                      props.buyData.transactionId.length
                    )}
                  `}</span>
                  <CopyToClipboard text={props.buyData.transactionId}
                    onCopy={() => copy(`${props.buyData.paymentMethod.label} hash copied`)}
                  >
                    <Button>
                      <Image src={window.location.origin + "/img/copy.svg"} />
                    </Button>
                  </CopyToClipboard>
                </div>
                <a
                  href={`${props.buyData.paymentMethod.blockExplorerUrl}tx/${props.buyData.transactionId}`}
                  target="_blank"
                >View on explorer</a>
              </div>
            </div>
            : null
          }
        </div>
        <div className="next-btn">
          <Button className="default-btn" onClick={() => props.reset()}>
            Home
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BuyPending;