import React from "react";
import {
  Image,
  Tab,
  Nav,
  Row,
  Container,
  Col,
  Accordion,
} from "react-bootstrap";

const FAQIndex = (props) => {
  return (
    <>
      <div className="faq-sec sm-padding">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8} lg={9} className="text-center">
              <div className="section-title">
                <h2>
                  <Image
                    src={window.location.origin + "/img/coin-img.png"}
                    className="section-coin-icon"
                  />
                  <div className="section-title-info">
                    Frequently
                    <span>Asked Questions</span>
                  </div>
                  <Image
                    src={window.location.origin + "/img/coin-img.png"}
                    className="section-coin-icon"
                  />
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Buy</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Sell</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              What is Webflow and why is it the best website
                              builder?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              What is your favorite template from BRIX
                              Templates?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              How do you clone a template from the Showcase?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              Why is BRIX Templates the best Webflow agency?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              When was Webflow officially launched?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Accordion defaultActiveKey="5">
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              What is Webflow and why is it the best website
                              builder?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="6">
                            <Accordion.Header>
                              What is your favorite template from BRIX
                              Templates?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="7">
                            <Accordion.Header>
                              How do you clone a template from the Showcase?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="8">
                            <Accordion.Header>
                              Why is BRIX Templates the best Webflow agency?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="9">
                            <Accordion.Header>
                              When was Webflow officially launched?
                            </Accordion.Header>
                            <Accordion.Body>
                              Vitae congue eu consequat ac felis placerat
                              vestibulum lectus mauris ultrices. Cursus sit amet
                              dictum sit amet justo donec enim diam porttitor
                              lacus luctus accumsan tortor posuere.
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
            <Col md={6}>
              <div className="faq-img-sec">
                <Image
                  src={window.location.origin + "/img/faq/faq-img.svg"}
                  className="faq-img m-auto"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FAQIndex;
