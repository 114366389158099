import React from 'react';
import { Image, Button } from 'react-bootstrap';

const BuyFailure = (props) => {

  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-back-btn-sec" onClick={() => props.reset()}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="buy-title-sec">
            <span>BUY</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
        </div>
        <div className="buy-body-sec">
          <div className="buy-success-img-sec">
            <Image
              src={window.location.origin + "/img/failure.gif"}
              className="buy-success-img"
            />
            <h5>
              Payment Failed.
            </h5>
          </div>
          <div className="next-btn">
            <Button className="default-btn" onClick={() => props.reset()}>
              Home
            </Button>
          </div>
        </div>
      </div>

    </>
  );

}

export default BuyFailure;