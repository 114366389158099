import React from "react";
import { Image, Form, InputGroup, Button } from "react-bootstrap";
import "../Exchange.css";

const AddBankIndex = (props) => {
    return (
        <>
            <div className="sell-sec">
                <div className="sell-header-sec">
                    <div className="sell-back-btn-sec" onClick={() => props.setStep(6)}>
                        <Image
                            src={window.location.origin + "/img/buy/back-arrow.svg"}
                            className="back-arrow-icon"
                        />
                        <span>Back</span>
                    </div>
                    <div className="sell-title-sec">
                        <span>SELL</span>
                        <Image
                            src={window.location.origin + "/img/buy/buy-icon.svg"}
                            className="sell-icon"
                        />
                    </div>
                </div>
                <div className="sell-body-sec">
                    <h3>Enter Bank Details</h3>
                    <Form className="sell-form">
                        <Form.Group className="mb-3 transaction-form-control">
                            <Form.Control
                                type="text"
                                placeholder="Account Number"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 transaction-form-control">
                            <Form.Control
                                type="text"
                                placeholder="Route Number"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 transaction-form-control">
                            <Form.Control
                                type="text"
                                placeholder="Account Name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 transaction-form-control">
                            <Form.Control
                                type="text"
                                placeholder="IFSC Code"
                            />
                        </Form.Group>
                        <div className="next-btn">
                            <Button className="default-btn" onClick={() => props.setStep(7)}>
                                Next
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default AddBankIndex;
