import React, { useState, useEffect } from "react";
import { Image, Form, InputGroup, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { buyTokensStart } from "../../store/actions/TransactionAction";
import StripePaymentContainer from "./CardPayment/StripePaymentContainer";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./CardPayment/PaymentForm";
import configuration from "react-global-configuration";

const CardPayment = (props) => {

  const stripeTestPromise = loadStripe(configuration.get("configData.stripe_publishable_key"))

  return (
    <>
      <div className="buy-sec">
        <div className="buy-header-sec">
          <div className="buy-back-btn-sec" onClick={() => props.setStep(2)}>
            <Image
              src={window.location.origin + "/img/buy/back-arrow.svg"}
              className="back-arrow-icon"
            />
            <span>Back</span>
          </div>
          <div className="buy-title-sec">
            <span>BUY</span>
            <Image
              src={window.location.origin + "/img/buy/buy-icon.svg"}
              className="buy-icon"
            />
          </div>
        </div>
        <div className="buy-body-sec">
          <h3>
            Publishing and graphic design, Lorem ipsum is a placeholder text{" "}
          </h3>
          <Elements stripe={stripeTestPromise}>
            <PaymentForm buyData={props.buyData} setStep={props.setStep} />
          </Elements>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  buyTokens: state.transaction.buyTokens,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(CardPayment);
