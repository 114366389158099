import {
	BUY_TOKENS_FAILURE,
	BUY_TOKENS_START,
	BUY_TOKENS_SUCCESS,
	FETCH_WALLET_TRANSACTION_DETAILS_FAILURE,
	FETCH_WALLET_TRANSACTION_DETAILS_START,
	FETCH_WALLET_TRANSACTION_DETAILS_SUCCESS,
	FETCH_WALLET_TRANSACTION_LIST_FAILURE,
	FETCH_WALLET_TRANSACTION_LIST_START,
	FETCH_WALLET_TRANSACTION_LIST_SUCCESS,
	FETCH_MORE_WALLET_TRANSACTION_LIST_START,
	SELL_TOKENS_FAILURE,
	SELL_TOKENS_START,
	SELL_TOKENS_SUCCESS,
	STRIPE_PAYMENT_FAILURE,
	STRIPE_PAYMENT_START,
	STRIPE_PAYMENT_SUCCESS
} from "./ActionConstant";


// Buy Tokens
export function buyTokensStart(data) { 
	return {
		type: BUY_TOKENS_START,
		data,
	};
}

export function buyTokensSuccess(data) {
	return {
		type: BUY_TOKENS_SUCCESS,
		data,
	};
}

export function buyTokensFailure(error) {
	return {
		type: BUY_TOKENS_FAILURE,
		error,
	};
}

// Sell Tokens
export function sellTokensStart(data) {
	return {
		type: SELL_TOKENS_START,
		data,
	};
}

export function sellTokensSuccess(data) {
	return {
		type: SELL_TOKENS_SUCCESS,
		data,
	};
}

export function sellTokensFailure(error) {
	return {
		type: SELL_TOKENS_FAILURE,
		error,
	};
}

// Wallet Transaction List
export function fetchWalletTransactionListStart(data) {
	return {
		type: FETCH_WALLET_TRANSACTION_LIST_START, 
		data,
	};
}

export function fetchWalletTransactionListSuccess(data) {
	return {
		type: FETCH_WALLET_TRANSACTION_LIST_SUCCESS,
		data,
	};
}

export function fetchWalletTransactionListFailure(error) {
	return {
		type: FETCH_WALLET_TRANSACTION_LIST_FAILURE,
		error,
	};
}
export function fetchMoreWalletTransactionListStart(data){
	return {
		type: FETCH_MORE_WALLET_TRANSACTION_LIST_START,
		data,

	}
}

// Wallet Transaction View
export function fetchWalletTransactionDetailsStart(data) {
	return {
		type: FETCH_WALLET_TRANSACTION_DETAILS_START,
		data,
	};
}

export function fetchWalletTransactionDetailsSuccess(data) {
	return {
		type: FETCH_WALLET_TRANSACTION_DETAILS_SUCCESS,
		data,
	};
}

export function fetchWalletTransactionDetailsFailure(error) {
	return {
		type: FETCH_WALLET_TRANSACTION_DETAILS_FAILURE,
		error,
	};
}

//stripe Payment
export const stripePaymentStart = (data) => ({
	type: STRIPE_PAYMENT_START,
	data,
});

export const stripePaymentSuccess = (data) => ({
	type: STRIPE_PAYMENT_SUCCESS,
	data,
});

export const stripePaymentFailure = (error) => ({
	type: STRIPE_PAYMENT_FAILURE,
	error,
})