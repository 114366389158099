import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js"
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Image, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { getErrorNotificationMessage } from "../../../helper/ToastNotification";
import { buyTokensStart, stripePaymentStart } from "../../../store/actions/TransactionAction";


const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" }
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee"
    }
  }
}

const PaymentForm = (props) => {
  const [skipRender, setSkipRender] = useState(true);
  const [paymentId, setpaymentId] = useState();
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardComplete, setCardComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const submitCard = async (e) => {
    e.preventDefault();
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });


    if (payload.error) {
      props.dispatch(getErrorNotificationMessage(payload.error))
    } else {
      console.log(payload);
      setpaymentId(payload.paymentMethod.id);
    }
  }

  useEffect(() => {
    if (paymentId) {
      props.dispatch(stripePaymentStart({
        card_token: paymentId,
        amount: props.buyData.exchangeValue,
      }));
    }
  }, [paymentId]);

  useEffect(() => {
    if (!skipRender && !props.stripePayment.loading && Object.keys(props.stripePayment.data).length > 0) {
      props.dispatch(buyTokensStart({
        tokens: props.buyData.buyTokens,
        amount: props.buyData.exchangeValue,
        amount_type: props.buyData.paymentMethod.value,
        wallet_address: props.buyData.toWalletAddress,
        to_wallet_address: props.buyData.toWalletAddress,
        user_payment_type: "CARD",
        payment_id: props.stripePayment.data.payment_id,
      }));
      props.setStep(4);
    }
    setSkipRender(false);
  }, [props.stripePayment]);

  // async function paymentSuccess() {
  // const payload = await stripe.paymentMethods.detach(paymentId);
  // if (payload.error) {
  //   props.dispatch(getErrorNotificationMessage(payload.error))
  // } else {
  // console.log(payload);

  // }
  // }

  return (
    <>
      <Form className="buy-form card-form" onSubmit={submitCard}>
        <Row>
          <Col md={12}>
            <Form.Group className="mb-3 transaction-form-control">
              <CardNumberElement
                options={{ showIcon: true }}
                className="form-control"
                onChange={e => {
                  setCardComplete(e.complete);
                }} />
            </Form.Group>
          </Col>
        </Row>
        <div className="card-details mb-3 transaction-form-control">
          <Row className="gy-3">
            <Col md={4}>
              <Form.Group>
                <CardExpiryElement className="form-control" onChange={e => {
                  setCardComplete(e.complete);
                }} />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <CardCvcElement className="form-control" onChange={e => {
                  setCardComplete(e.complete);
                }} />
              </Form.Group>
            </Col>
            <Col md={4}>
              <div className="card-image">
                <Image
                  src={window.location.origin + "/img/visa.svg"}
                  className="card-icon"
                />
                <Image
                  src={window.location.origin + "/img/american-express.svg"}
                  className="card-icon"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="pay-amount">
          Pay Amount - <span>{props.buyData.exchangeValue}$</span>
        </div>

        <div className="next-btn">
          <Button
            type="submit"
            className="default-btn"
            disabled={props.stripePayment.buttonDisable}
          >
            {
              props.stripePayment.loadingButtonContent
                ? props.stripePayment.loadingButtonContent
                : "Buy Tokens"
            }
          </Button>
        </div>
      </Form>
    </>
  )
}

const mapStateToProps = (state) => ({
  buyTokens: state.transaction.buyTokens,
  stripePayment: state.transaction.stripePayment,
})

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);